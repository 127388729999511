import {
    ApiReservePreviewQueryT,
    ReservePreviewQueryChangesT,
    ReservePreviewQueryT,
    ReserveQueryChangesT,
    ReserveT,
} from '../models';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';

const setReversePreviewDefaultQuery = (
    queryChanges: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): ReservePreviewQueryT | null => {
    if (!queryChanges) {
        return null;
    }

    const newQueryChanges: ReservePreviewQueryT = { ...queryChanges };

    reserve?.points?.forEach((point, index) => {
        if (!newQueryChanges.timeWindows) {
            newQueryChanges.timeWindows = [];
        }

        if (!newQueryChanges.timeWindows[index]) {
            newQueryChanges.timeWindows[index] = {};
        }

        if (point.defaultTimeWindow?.start && !newQueryChanges.timeWindows[index].timeWindowFrom) {
            newQueryChanges.timeWindows[index].timeWindowFrom = point.defaultTimeWindow?.start;
        }

        if (point.defaultTimeWindow?.end && !newQueryChanges.timeWindows[index].timeWindowTo) {
            newQueryChanges.timeWindows[index].timeWindowTo = point.defaultTimeWindow?.end;
        }
    });

    return newQueryChanges;
};

const getPreviewReverseQueryChanges = (
    changes: ReserveQueryChangesT,
    prevReservePreviewQuery: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): ReservePreviewQueryChangesT => {
    const queryChanges: ReservePreviewQueryChangesT = {};

    const isAvailableLane = reserve?.contractLanes?.some((contractLane) => contractLane?.id === changes?.laneId);
    const isResetChange = changes?.laneId === null;
    if ((changes?.laneId && isAvailableLane) || isResetChange) {
        queryChanges.laneId = changes?.laneId;
    }

    const prepareRoutePointChanges = (
        prevRoutePointChanges: ReservePreviewQueryT['timeWindows'][number],
        changes: NonNullable<ReserveQueryChangesT['fullRouteChange']>[number] | null | undefined,
        index: number,
    ): ReservePreviewQueryT['timeWindows'][number] => {
        const result: ReservePreviewQueryT['timeWindows'][number] = {
            ...prevRoutePointChanges,
        };

        const timeWindowFrom = changes?.timeWindowFrom;
        if (!isUndefined(timeWindowFrom)) {
            result.timeWindowFrom = timeWindowFrom;
        }

        const timeWindowTo = changes?.timeWindowTo;
        if (!isUndefined(timeWindowTo)) {
            result.timeWindowTo = timeWindowTo;
        }

        const isBrokerTimeWindow = changes?.isBrokerTimeWindow;
        if (isBoolean(isBrokerTimeWindow)) {
            result.isBrokerTimeWindow = isBrokerTimeWindow;
        }

        const id = reserve?.points?.[index]?.id;
        if (!isUndefined(id)) {
            result.id = id;
        }

        return result;
    };

    if (changes?.fullRouteChange) {
        changes.fullRouteChange.forEach((routePointChanges, index) => {
            if (!queryChanges?.timeWindows) {
                queryChanges.timeWindows = [];
            }

            queryChanges.timeWindows[index] = prepareRoutePointChanges({}, routePointChanges, index);
        });
    }

    if (changes?.routePointChange) {
        const prevQueryChangesTimeWindows = cloneDeep(prevReservePreviewQuery?.timeWindows || []);

        prevQueryChangesTimeWindows[changes.routePointChange.index] = prepareRoutePointChanges(
            prevQueryChangesTimeWindows[changes.routePointChange.index],
            changes.routePointChange.changes,
            changes.routePointChange.index,
        );

        queryChanges.timeWindows = prevQueryChangesTimeWindows;
    }

    if (changes?.addRoutePoint) {
        const prevQueryChangesTimeWindows = cloneDeep(prevReservePreviewQuery?.timeWindows || []);

        prevQueryChangesTimeWindows.splice(
            changes.addRoutePoint.index,
            0,
            prepareRoutePointChanges({}, changes.addRoutePoint.changes, changes.addRoutePoint.index),
        );

        queryChanges.timeWindows = prevQueryChangesTimeWindows;
    }

    if (changes?.deleteRoutePoint) {
        const prevQueryChangesTimeWindows = cloneDeep(prevReservePreviewQuery?.timeWindows || []);

        prevQueryChangesTimeWindows.splice(changes.deleteRoutePoint.index, 1);

        queryChanges.timeWindows = prevQueryChangesTimeWindows;
    }

    const reserveId = reserve?.reserveId || null;
    if (reserveId) {
        queryChanges.reserveId = reserveId;
    }

    return queryChanges;
};

const mergePreviewReverseQuery = (
    prevQuery: ReservePreviewQueryT | null,
    changes: ReservePreviewQueryChangesT,
): ReservePreviewQueryT | null => {
    const query: ReservePreviewQueryT = {
        reserveId: null,
        laneId: null,
        ...prevQuery,
        ...changes,
        timeWindows: changes?.timeWindows || prevQuery?.timeWindows || [],
    };

    return query;
};

const correctionPreviewReverseQueryAfterReserve = (
    reservePreviewQuery: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): ReservePreviewQueryT | null => {
    if (!reservePreviewQuery) {
        return null;
    }

    const correctedReservePreviewQuery: ReservePreviewQueryT = {
        ...reservePreviewQuery,
    };

    if (correctedReservePreviewQuery?.laneId && reserve) {
        const availableLanes = reserve.contractLanes || [];
        const isAvailableLane = availableLanes.some(
            (contractLane) => contractLane?.id === correctedReservePreviewQuery?.laneId,
        );
        if (!isAvailableLane) {
            correctedReservePreviewQuery.laneId = null;
        }
    }

    return correctedReservePreviewQuery;
};

const getApiPreviewReverseQuery = (
    query: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): ApiReservePreviewQueryT | null => {
    if (!query) {
        return null;
    }

    const { reserveId } = query;
    if (!reserveId) {
        return null;
    }

    const points = reserve?.points || [];
    const pointsById = keyBy(points, (point) => point.id);

    const timeWindows = points
        .map((point, index) => {
            const timeWindow = query.timeWindows[index] || null;

            return {
                brokerWindow: !!timeWindow?.isBrokerTimeWindow,
                from: timeWindow?.timeWindowFrom || null,
                to: timeWindow?.timeWindowTo || null,
                id: point.id,
            };
        })
        .filter((timeWindow) => {
            const point = pointsById[timeWindow?.id as string];

            return !point?.driveThru;
        });

    const checkIsValidTimeWindow = (
        timeWindow: (typeof timeWindows)[number],
    ): timeWindow is NonNullable<ApiReservePreviewQueryT['timeWindows']>[number] => {
        return !!timeWindow?.from && !!timeWindow?.to && !!timeWindow?.id;
    };

    const isValidTimeWindows = timeWindows?.every(checkIsValidTimeWindow) && !!timeWindows?.length;
    if (!isValidTimeWindows) {
        return null;
    }

    return {
        timeWindows,
        reserveId,
    };
};

export {
    getPreviewReverseQueryChanges,
    mergePreviewReverseQuery,
    getApiPreviewReverseQuery,
    setReversePreviewDefaultQuery,
    correctionPreviewReverseQueryAfterReserve,
};
