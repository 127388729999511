import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './ContactCell.scss';
import { PartnerT } from 'broker-admin/store/partners/models';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import Avatar from 'common/components/Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import Link, { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TableCellLink from 'common/components/Table/cell-renderers/TableCellLink/TableCellLink';

type PropsT = {
    partner: PartnerT;
    className?: string;
};

const cx = classNames.bind(styles);

const partnerTypeMap: Record<PartnerT['profileType'][number], PartnerTypeEnum> = {
    SHIPPER: PartnerTypeEnum.shipper,
    CARRIER: PartnerTypeEnum.carrier,
    BROKER: PartnerTypeEnum.broker,
};

const positionMap: Record<NonNullable<PartnerT['contactPosition']>, string> = {
    it: 'common:team-members.position.it',
    management: 'common:team-members.position.management',
    operator: 'common:team-members.position.operator',
    owner: 'common:team-members.position.owner',
    sales: 'common:team-members.position.sales',
};

const ContactCell: React.FC<PropsT> = React.memo((props) => {
    const { partner, className } = props;

    const { t } = useTranslation();

    const { contactEmail, contactId, contactName, contactPhone, contactPosition, contactSurname, id, profileType } =
        partner;

    const contactFullName = `${contactName} ${contactSurname}`;

    const openLeftSidebar = useOpenLeftSidebar();

    const openUserDetails = () => {
        if (!contactId) {
            logWarning(`Failed to open user details, empty contactId`);
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId: id,
            partnerType: partnerTypeMap[profileType[0]],
            contactId,
        });
    };

    return (
        <div className={cs(cx('created-date'), className)}>
            {contactId && (
                <Tooltip
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={240}>
                            <div className={cx('tooltip')}>
                                <div className={cx('tooltip__avatar')}>
                                    <Avatar className={cx('avatar')} hash={contactFullName} />
                                </div>
                                <div className={cx('tooltip__info')}>
                                    <div className={cx('tooltip__full-name')}>{contactFullName}</div>
                                    {contactPosition && (
                                        <div className={cx('tooltip__role')}>{t(positionMap[contactPosition])}</div>
                                    )}
                                    {!!contactEmail && (
                                        <Link
                                            to={`mailto:${contactEmail}`}
                                            theme={LinkThemeEnum.boldBrandAccent}
                                            size={LinkSizeEnum.medium}
                                            isExternal
                                        >
                                            {contactEmail}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </TooltipContent>
                    }
                    position={TooltipPositionEnum.centerLeft}
                    theme={TooltipThemeEnum.black}
                >
                    {() => (
                        <TableCellLink
                            onClick={openUserDetails}
                            className={cx('full-name')}
                            theme={LinkThemeEnum.boldBrandDark}
                            size={LinkSizeEnum.medium}
                        >
                            {contactFullName}
                        </TableCellLink>
                    )}
                </Tooltip>
            )}
            <div className={cx('phone')}>{contactPhone}</div>
        </div>
    );
});

export default ContactCell;
