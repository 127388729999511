import React from 'react';
import classNames from 'classnames/bind';

import styles from './DropdownTreeInput.scss';
import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseLayout from '../base/DropdownBaseLayout/DropdownBaseLayout';
import DropdownBaseButtonTrigger from '../base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import cs from 'classnames';
import CheckboxTree, { getAllSubValues, TreeNodeT } from 'design-system/components/CheckboxTree/CheckboxTree';
import FilterPill, { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';

const cx = classNames.bind(styles);

export type IconMetaT = {
    isEmpty: boolean;
    isFocus: boolean;
    isPressed: boolean;
    isDisabled: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    hasChanges: boolean;
    hasValue: boolean;
};

export type PropsT<ValueT> = {
    selectedValues: Array<ValueT>;
    placeholder?: string;
    isDisabled?: boolean;
    isInline?: boolean;
    hasWarning?: boolean;
    hasError?: boolean;
    options: Array<TreeNodeT<ValueT>>;
    onSelect: (values: Array<ValueT>) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    overlayPosition: DropdownOverlayPositionEnum;
    className?: string;
    hasChanges?: boolean;
    triggerClassName?: string;
    overlayClassName?: string;
    testSelector?: string;
    hasClearControl?: boolean;
    isLoading?: boolean;
    renderLeftIcon?: (iconMeta: IconMetaT) => React.ReactNode;
};

const DropdownTreeInput = <ValueT,>(props: PropsT<ValueT>): React.ReactElement => {
    const {
        selectedValues,
        placeholder,
        onSelect,
        options,
        isDisabled,
        isInline,
        hasWarning,
        hasError,
        overlayPosition,
        className,
        hasChanges,
        triggerClassName,
        overlayClassName,
        onFocus,
        onBlur,
        testSelector,
        hasClearControl,
        isLoading,
        renderLeftIcon,
    } = props;

    const [isOpen, toggleOpen] = React.useState(false);

    const handleOpen = (): void => {
        if (isDisabled) {
            return;
        }
        if (onFocus) {
            onFocus();
        }
        toggleOpen(true);
    };

    const handleClose = (): void => {
        if (onBlur) {
            onBlur();
        }
        toggleOpen(false);
    };

    const handleOuterEvent = (): void => {
        if (onBlur) {
            onBlur();
        }
        handleClose();
    };

    const renderTrigger = (options: Array<TreeNodeT<ValueT>>, placeholder: string | undefined): React.ReactNode => {
        if (!options?.length) {
            return placeholder;
        }

        return (
            <>
                {options.map((option, optionIndex) => {
                    return (
                        <FilterPill
                            className={cx('tag')}
                            key={optionIndex}
                            isCompact
                            label={option.label}
                            withCloser
                            theme={FilterPillThemeEnum.charcoal}
                            onClick={() => {
                                const optionSubValues = getAllSubValues([option]);
                                const optionSubValuesSet = new Set(optionSubValues);
                                const newValues = selectedValues.filter((value) => {
                                    return !optionSubValuesSet.has(value);
                                });
                                onSelect(newValues);
                            }}
                        />
                    );
                })}
            </>
        );
    };

    const selectedOptions = React.useMemo((): Array<TreeNodeT<ValueT>> => {
        const result: Array<TreeNodeT<ValueT>> = [];

        const valuesSet = new Set(selectedValues);

        const findSelectedOption = (treeNode: TreeNodeT<ValueT>): void => {
            const subValues = getAllSubValues([treeNode]);
            if (!subValues?.length) {
                return;
            }

            const isExactValue = subValues.every((subValue) => valuesSet.has(subValue));
            if (isExactValue) {
                result.push(treeNode);
                subValues.forEach((subValue) => valuesSet.delete(subValue));
                return;
            }

            const isPartlyValue = subValues.some((subValue) => valuesSet.has(subValue));
            if (isPartlyValue) {
                treeNode.children.forEach((subTreeNode) => findSelectedOption(subTreeNode));
            }
        };

        options.forEach(findSelectedOption);

        return result;
    }, [options, selectedValues]);

    const handleReset = React.useCallback(() => {
        onSelect([]);
    }, [onSelect]);

    const hasValues = !!selectedOptions.length;

    return (
        <DropdownBaseLayout
            isInline={isInline}
            isOpen={isOpen}
            className={className}
            onClose={handleOuterEvent}
            triggerNode={
                <DropdownBaseButtonTrigger
                    isEmpty={!hasValues}
                    isPressed={isOpen}
                    isDisabled={isDisabled}
                    hasChanges={hasChanges}
                    hasWarning={hasWarning}
                    hasError={hasError}
                    className={cs(cx('trigger'), triggerClassName)}
                    testSelector={testSelector}
                    onClick={handleOpen}
                    renderLeftIcon={
                        renderLeftIcon
                            ? (iconMeta) =>
                                  renderLeftIcon({
                                      ...iconMeta,
                                      hasValue: hasValues,
                                  })
                            : undefined
                    }
                    isShowClearControl={hasClearControl}
                    onReset={hasClearControl ? handleReset : undefined}
                    isMultiLine
                    renderRightIcon={() =>
                        isLoading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
                    }
                >
                    {renderTrigger(selectedOptions, placeholder)}
                </DropdownBaseButtonTrigger>
            }
            overlayPosition={overlayPosition}
            overlayClassName={cs(cx('overlay'), overlayClassName)}
            overlayNode={
                <>
                    {options.map((option, index): React.ReactElement => {
                        return (
                            <div key={index} className={cx('option')}>
                                <CheckboxTree
                                    trees={[option]}
                                    values={selectedValues}
                                    onChange={onSelect}
                                    isFullWidth
                                />
                            </div>
                        );
                    })}
                </>
            }
        />
    );
};

export default DropdownTreeInput;
