import React, { useMemo } from 'react';

export type SyncAssignmentFormMapStateContextValueT = {
    hasDropTrailerPointError: boolean;
    setHasDropTrailerPointError: (hasDropTrailerPointError: boolean) => void;
    hasDropTruckPointError: boolean;
    setHasDropTruckPointError: (hasDropTruckPointError: boolean) => void;
};

export const usySyncMapState = (): SyncAssignmentFormMapStateContextValueT => {
    const [hasDropTrailerPointError, setHasDropTrailerPointError] = React.useState<boolean>(false);
    const [hasDropTruckPointError, setHasDropTruckPointError] = React.useState<boolean>(false);

    return useMemo(() => {
        return {
            hasDropTrailerPointError,
            setHasDropTrailerPointError,
            hasDropTruckPointError,
            setHasDropTruckPointError,
        };
    }, [hasDropTrailerPointError, hasDropTruckPointError]);
};
