import React from 'react';

export type AssetsSelectionContextValueT = {
    isBlockedSelectTruck: boolean;
    isBlockedSelectTrailer: boolean;
};

export type AssetsSelectionContextT = {
    value: AssetsSelectionContextValueT;
    setValue: (value: AssetsSelectionContextValueT) => void;
};

const AssetsSelectionContext = React.createContext<AssetsSelectionContextT | null>(null);

export default AssetsSelectionContext;
