import * as React from 'react';
import { Trans } from 'react-i18next';
import CityName from '../entities/CityName/CityName';
import Identifier from 'common/components/notification-cards/entities/Identifier/Identifier';
import classNames from 'classnames/bind';
import styles from './ShipmentMissedCard.scss';

const cx = classNames.bind(styles);

type PropsT = {
    shipmentNumber: React.ReactNode;
    dispatchNumber: React.ReactNode;
    originCity: React.ReactNode;
    destinationCity: React.ReactNode;
};

const ShipmentMissedCard: React.FC<PropsT> = React.memo((props) => {
    const { shipmentNumber, dispatchNumber, originCity, destinationCity } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="notifications.shipment-missed"
                components={{
                    shipmentNumber: <Identifier id={shipmentNumber} />,
                    dispatchNumber: <Identifier id={dispatchNumber} />,
                    cityFrom: <CityName cityName={originCity} />,
                    cityTo: <CityName cityName={destinationCity} />,
                }}
            />
        </div>
    );
});

export default ShipmentMissedCard;
