import {
    AssignActionT,
    AssignBeginActionT,
    AssignErrorActionT,
    ASSIGNMENT_REQUEST,
    ASSIGNMENT_REQUEST_BEGIN,
    ASSIGNMENT_REQUEST_ERROR,
    ASSIGNMENT_REQUEST_SUCCESS,
    AssignSuccessActionT,
    CHANGE_USER_SELECTION,
    ChangeUserSelectionActionT,
    FETCH_ASSET_SCHEDULE,
    FETCH_ASSET_SCHEDULE_BEGIN,
    FETCH_ASSET_SCHEDULE_ERROR,
    FETCH_ASSET_SCHEDULE_SUCCESS,
    FETCH_AVAILABLE_CARRIERS_REQUEST,
    FETCH_AVAILABLE_CARRIERS_REQUEST_BEGIN,
    FETCH_AVAILABLE_CARRIERS_REQUEST_ERROR,
    FETCH_AVAILABLE_CARRIERS_REQUEST_SUCCESS,
    FETCH_AVAILABLE_VEHICLES_REQUEST,
    FETCH_AVAILABLE_VEHICLES_REQUEST_BEGIN,
    FETCH_AVAILABLE_VEHICLES_REQUEST_ERROR,
    FETCH_AVAILABLE_VEHICLES_REQUEST_SUCCESS,
    FetchAssetScheduleActionT,
    FetchAssetScheduleBeginActionT,
    FetchAssetScheduleErrorActionT,
    FetchAssetScheduleSuccessActionT,
    FetchAvailableCarriersActionT,
    FetchAvailableCarriersBeginActionT,
    FetchAvailableCarriersErrorActionT,
    FetchAvailableCarriersSuccessActionT,
    FetchAvailableVehiclesActionT,
    FetchAvailableVehiclesBeginActionT,
    FetchAvailableVehiclesErrorActionT,
    FetchAvailableVehiclesSuccessActionT,
    FIND_TRAILER_REQUEST,
    FIND_TRAILER_REQUEST_BEGIN,
    FIND_TRAILER_REQUEST_ERROR,
    FIND_TRAILER_REQUEST_SUCCESS,
    FIND_TRUCK_REQUEST,
    FIND_TRUCK_REQUEST_BEGIN,
    FIND_TRUCK_REQUEST_ERROR,
    FIND_TRUCK_REQUEST_SUCCESS,
    FindTrailerActionT,
    FindTrailerBeginActionT,
    FindTrailerErrorActionT,
    FindTrailerSuccessActionT,
    FindTruckActionT,
    FindTruckBeginActionT,
    FindTruckErrorActionT,
    FindTruckSuccessActionT,
    INIT,
    INIT_USER_SELECTION,
    InitActionT,
    InitUserSelectionActionT,
    PREDICT_SELF_COST_REQUEST,
    PREDICT_SELF_COST_REQUEST_BEGIN,
    PREDICT_SELF_COST_REQUEST_ERROR,
    PREDICT_SELF_COST_REQUEST_SUCCESS,
    PredictSelfCostActionT,
    PredictSelfCostBeginActionT,
    PredictSelfCostErrorActionT,
    PredictSelfCostSuccessActionT,
    REFRESH_TRAILER_REQUEST,
    REFRESH_TRUCK_REQUEST,
    RefreshTrailerActionT,
    RefreshTruckActionT,
    RESET,
    ResetActionT,
    SET_ASSET_SCHEDULE,
    SET_INIT_ASSETS_INFO,
    SET_EVENT_LOAD_ROUTE,
    SET_SELECTED_SCHEDULE_EVENT_ID,
    SET_SHOW_SELECTED_ASSET_SCHEDULES,
    SetAssetScheduleActionT,
    SetEventLoadRouteActionT,
    SetSelectedScheduleEventIdActionT,
    SetShowSelectedAssetScheduleActionT,
    SetInitAssetsInfoActionT,
    FETCH_ASSIGN_DETAILS_REQUEST,
    FETCH_ASSIGN_DETAILS_REQUEST_BEGIN,
    FETCH_ASSIGN_DETAILS_REQUEST_SUCCESS,
    FETCH_ASSIGN_DETAILS_REQUEST_ERROR,
    FetchAssignDetailsActionT,
    FetchAssignDetailsBeginActionT,
    FetchAssignDetailsSuccessActionT,
    FetchAssignDetailsErrorActionT,
} from './types';

import {
    ApiAssignmentDetailsT,
    AssignmentVehiclesSuggestItemT,
    AssignmentVehiclesT,
    AvailableVehiclesQueryT,
    CarrierT,
    FindTrailerQueryT,
    FindTruckQueryT,
    ScheduleEventIdT,
    ScheduleEventT,
    SelfCostPredictionQueryT,
    SelfCostPredictionT,
    UserSelectionT,
} from './models';

export const initUserSelection = (
    dispatchId: DispatchIdT | null,
    changes: Partial<UserSelectionT>,
): InitUserSelectionActionT => ({
    type: INIT_USER_SELECTION,
    changes,
    dispatchId,
});

export const changeUserSelection = (
    dispatchId: DispatchIdT | null,
    changes: Partial<UserSelectionT>,
): ChangeUserSelectionActionT => ({
    type: CHANGE_USER_SELECTION,
    dispatchId,
    changes,
});

export const fetchAvailableVehicles = (query: AvailableVehiclesQueryT): FetchAvailableVehiclesActionT => ({
    type: FETCH_AVAILABLE_VEHICLES_REQUEST,
    query,
});

export const fetchAvailableVehiclesBegin = (query: AvailableVehiclesQueryT): FetchAvailableVehiclesBeginActionT => ({
    type: FETCH_AVAILABLE_VEHICLES_REQUEST_BEGIN,
    query,
});

export const fetchAvailableVehiclesSuccess = (
    query: AvailableVehiclesQueryT,
    vehicles: AssignmentVehiclesT[],
): FetchAvailableVehiclesSuccessActionT => ({
    type: FETCH_AVAILABLE_VEHICLES_REQUEST_SUCCESS,
    vehicles,
    query,
});

export const fetchAvailableVehiclesError = (
    query: AvailableVehiclesQueryT,
    error: Error,
): FetchAvailableVehiclesErrorActionT => ({
    type: FETCH_AVAILABLE_VEHICLES_REQUEST_ERROR,
    error,
    query,
});

export const fetchAvailableCarriers = (options?: FetchOptionsT): FetchAvailableCarriersActionT => ({
    type: FETCH_AVAILABLE_CARRIERS_REQUEST,
    options,
});

export const fetchAvailableCarriersBegin = (): FetchAvailableCarriersBeginActionT => ({
    type: FETCH_AVAILABLE_CARRIERS_REQUEST_BEGIN,
});

export const fetchAvailableCarriersSuccess = (carriers: CarrierT[]): FetchAvailableCarriersSuccessActionT => ({
    type: FETCH_AVAILABLE_CARRIERS_REQUEST_SUCCESS,
    carriers,
});

export const fetchAvailableCarriersError = (error: Error): FetchAvailableCarriersErrorActionT => ({
    type: FETCH_AVAILABLE_CARRIERS_REQUEST_ERROR,
    error,
});

export const setInitAssetsInfo = (
    truck: AssignmentVehiclesSuggestItemT | null,
    trailer: AssignmentVehiclesSuggestItemT | null,
): SetInitAssetsInfoActionT => ({
    type: SET_INIT_ASSETS_INFO,
    truck,
    trailer,
});

export const refreshTrailer = (query: FindTrailerQueryT): RefreshTrailerActionT => ({
    type: REFRESH_TRAILER_REQUEST,
    query,
});

export const findTrailer = (query: FindTrailerQueryT): FindTrailerActionT => ({
    type: FIND_TRAILER_REQUEST,
    query,
});

export const findTrailerBegin = (query: FindTrailerQueryT): FindTrailerBeginActionT => ({
    type: FIND_TRAILER_REQUEST_BEGIN,
    query,
});

export const findTrailerSuccess = (
    query: FindTrailerQueryT,
    trailersSuggestItems: AssignmentVehiclesSuggestItemT[],
): FindTrailerSuccessActionT => ({
    type: FIND_TRAILER_REQUEST_SUCCESS,
    trailersSuggestItems,
    query,
});

export const findTrailerError = (query: FindTrailerQueryT, error: Error): FindTrailerErrorActionT => ({
    type: FIND_TRAILER_REQUEST_ERROR,
    error,
    query,
});

export const refreshTruck = (query: FindTruckQueryT): RefreshTruckActionT => ({
    type: REFRESH_TRUCK_REQUEST,
    query,
});

export const findTruck = (query: FindTruckQueryT): FindTruckActionT => ({
    type: FIND_TRUCK_REQUEST,
    query,
});

export const findTruckBegin = (query: FindTruckQueryT): FindTruckBeginActionT => ({
    type: FIND_TRUCK_REQUEST_BEGIN,
    query,
});

export const findTruckSuccess = (
    query: FindTruckQueryT,
    trucksSuggestItems: AssignmentVehiclesSuggestItemT[],
): FindTruckSuccessActionT => ({
    type: FIND_TRUCK_REQUEST_SUCCESS,
    trucksSuggestItems,
    query,
});

export const findTruckError = (query: FindTruckQueryT, error: Error): FindTruckErrorActionT => ({
    type: FIND_TRUCK_REQUEST_ERROR,
    error,
    query,
});

export const predictSelfCost = (query: SelfCostPredictionQueryT): PredictSelfCostActionT => ({
    type: PREDICT_SELF_COST_REQUEST,
    query,
});

export const predictSelfCostBegin = (query: SelfCostPredictionQueryT): PredictSelfCostBeginActionT => ({
    type: PREDICT_SELF_COST_REQUEST_BEGIN,
    query,
});

export const predictSelfCostSuccess = (
    query: SelfCostPredictionQueryT,
    selfCostPrediction: SelfCostPredictionT | null,
): PredictSelfCostSuccessActionT => ({
    type: PREDICT_SELF_COST_REQUEST_SUCCESS,
    selfCostPrediction,
    query,
});

export const predictSelfCostError = (query: SelfCostPredictionQueryT, error: Error): PredictSelfCostErrorActionT => ({
    type: PREDICT_SELF_COST_REQUEST_ERROR,
    error,
    query,
});

export const assign = (dispatchId: DispatchIdT, assignmentContextId: AssignmentContextIdT): AssignActionT => ({
    type: ASSIGNMENT_REQUEST,
    dispatchId,
    assignmentContextId,
});

export const assignBegin = (): AssignBeginActionT => ({
    type: ASSIGNMENT_REQUEST_BEGIN,
});

export const assignSuccess = (): AssignSuccessActionT => ({
    type: ASSIGNMENT_REQUEST_SUCCESS,
});

export const assignError = (error: Error): AssignErrorActionT => ({
    type: ASSIGNMENT_REQUEST_ERROR,
    error,
});

export const fetchAssetSchedule = (assetId: AssetIdT, dispatchId: DispatchIdT): FetchAssetScheduleActionT => ({
    type: FETCH_ASSET_SCHEDULE,
    assetId,
    dispatchId,
});

export const fetchAssetScheduleBegin = (assetId: AssetIdT): FetchAssetScheduleBeginActionT => ({
    type: FETCH_ASSET_SCHEDULE_BEGIN,
    assetId,
});

export const fetchAssetScheduleError = (assetId: AssetIdT, error: Error): FetchAssetScheduleErrorActionT => ({
    type: FETCH_ASSET_SCHEDULE_ERROR,
    error,
    assetId,
});

export const fetchAssetScheduleSuccess = (assetId: AssetIdT): FetchAssetScheduleSuccessActionT => ({
    type: FETCH_ASSET_SCHEDULE_SUCCESS,
    assetId,
});

export const fetchAssignDetails = (dispatchId: DispatchIdT, tourId: TourIdT): FetchAssignDetailsActionT => ({
    type: FETCH_ASSIGN_DETAILS_REQUEST,
    tourId,
    dispatchId,
});

export const fetchAssignDetailsBegin = (): FetchAssignDetailsBeginActionT => ({
    type: FETCH_ASSIGN_DETAILS_REQUEST_BEGIN,
});

export const fetchAssignDetailsSuccess = (details: ApiAssignmentDetailsT | null): FetchAssignDetailsSuccessActionT => ({
    type: FETCH_ASSIGN_DETAILS_REQUEST_SUCCESS,
    details,
});

export const fetchAssignDetailsError = (error: Error): FetchAssignDetailsErrorActionT => ({
    type: FETCH_ASSIGN_DETAILS_REQUEST_ERROR,
    error,
});

export const setAssetSchedule = (
    assetId: AssetIdT,
    events: Array<ScheduleEventT>,
    currentEventId: ScheduleEventIdT | null,
): SetAssetScheduleActionT => ({
    type: SET_ASSET_SCHEDULE,
    assetId,
    events,
    currentEventId,
});

export const setEventLoadRoute = (
    assetId: AssetIdT,
    scheduleEventId: ScheduleEventIdT,
    polylines: Array<GooglePolylineT>,
): SetEventLoadRouteActionT => ({
    type: SET_EVENT_LOAD_ROUTE,
    scheduleEventId,
    polylines,
    assetId,
});

export const setShowSelectedAssetSchedule = (
    isShowSelectedAssetSchedules: boolean,
): SetShowSelectedAssetScheduleActionT => ({
    type: SET_SHOW_SELECTED_ASSET_SCHEDULES,
    isShowSelectedAssetSchedules,
});

export const setSelectedScheduleEventId = (
    selectedScheduleEventId: ScheduleEventIdT | null,
): SetSelectedScheduleEventIdActionT => ({
    type: SET_SELECTED_SCHEDULE_EVENT_ID,
    selectedScheduleEventId,
});

export const initDispatchAssignment = (): InitActionT => ({
    type: INIT,
});

export const resetDispatchAssignment = (): ResetActionT => ({
    type: RESET,
});
