import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT, UpdateWayEnum } from './constants';
import { checkNotEmpty, checkIsPositiveNumberValue, checkMinValue, checkMaxValue } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [FieldsEnum.updateWay] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    let errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    if (values[FieldsEnum.updateWay] === UpdateWayEnum.newRate) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.rate], values),
            ...checkIsPositiveNumberValue(t, [FieldsEnum.rate], values),
            ...checkMinValue(t, [FieldsEnum.rate], values, 0),
            ...checkMaxValue(t, [FieldsEnum.rate], values, 10),
        };
    }

    if (values[FieldsEnum.updateWay] === UpdateWayEnum.assignContract) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.contractId], values),
        };
    }

    return errors;
};

export default validate;
