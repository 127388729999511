import * as React from 'react';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { selectCountriesAllCodes, selectCountriesByCode } from 'common/store/countries-dict/selectors';

import DropdownSearchMultipleInput from 'design-system/components/dropdowns/DropdownSearchMultipleInput/DropdownSearchMultipleInput';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import ExcludedCountry from './ExcludedCountry/ExcludedCountry';

import styles from './ExcludeCountriesMapWidget.scss';
import { CountryCodeT } from 'common/utils/api/models';
import { prepareSearchQuery } from 'common/utils/search';

const cx = classNames.bind(styles);

type PropsT<T extends CountryCodeT> = {
    className?: string;
    excludedCountries: Array<T>;
    setExcludedCountries: (excludedCountries: Array<T>) => void;
    triggerLabel: string;
    inputPlaceholder: string;
    additionalTagsNode?: React.ReactNode;
};

const ExcludeCountriesMapWidget = <T extends CountryCodeT>(props: PropsT<T>) => {
    const { className, excludedCountries, setExcludedCountries, triggerLabel, inputPlaceholder, additionalTagsNode } =
        props;

    const [query, setQuery] = React.useState<string>('');

    const countriesAllCodes = useSelector(selectCountriesAllCodes);
    const countryByCode = useSelector(selectCountriesByCode);

    const renderOption = (countryCode: T) => {
        return <CountyFlagLabel country={countryByCode[countryCode]} />;
    };

    const getOptionValue = (countryCode: T) => countryCode;

    const renderTrigger = (): React.ReactNode => {
        return triggerLabel;
    };

    const removeCountry = (countryCode: T): void => {
        const newExcludedCountries = excludedCountries.filter(
            (excludedCountryCode) => excludedCountryCode !== countryCode,
        );

        setExcludedCountries(newExcludedCountries);
    };

    const filteredCountriesCodes = React.useMemo(() => {
        const preparedQuery = prepareSearchQuery(query);

        return countriesAllCodes.filter((countryCode) => {
            const country = countryByCode[countryCode];

            const label = country?.userLangDisplayName || '';
            const preparedLabel = prepareSearchQuery(label);

            return preparedLabel.includes(preparedQuery);
        });
    }, [countriesAllCodes, query, countryByCode]);

    return (
        <div className={cs(cx('wrap'), className)}>
            <DropdownSearchMultipleInput<T, T>
                className={cx('countries')}
                overlayClassName={cx('countries-overlay')}
                selectedValues={excludedCountries}
                inputPlaceholder={inputPlaceholder}
                onSelect={setExcludedCountries}
                options={filteredCountriesCodes as Array<T>}
                renderOption={renderOption}
                getOptionValue={getOptionValue}
                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                renderTrigger={renderTrigger}
                onChangeQuery={setQuery}
            />
            {excludedCountries.map((countryCode: T, index: number) => {
                const country = countryByCode[countryCode];

                return (
                    <ExcludedCountry
                        key={index}
                        countryCode={countryCode}
                        countryLabel={country?.userLangDisplayName}
                        className={cx('country')}
                        onClick={removeCountry}
                    />
                );
            })}
            {additionalTagsNode}
        </div>
    );
};

export default React.memo(ExcludeCountriesMapWidget) as typeof ExcludeCountriesMapWidget;
