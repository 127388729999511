import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLaneCreationMap.scss';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import DestinationPinIcon from 'common/icons/DestinationPinIcon';
import OriginPinIcon from 'common/icons/OriginPinIcon';
import GoogleMapContext, { GoogleMapContextT } from 'common/contexts/google-map-context';
import Estimate from './Estimate/Estimate';
import { logWarning } from 'common/utils/logger';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import { GoogleMapsGeocoderApi } from 'common/utils/google-maps-api/google-maps-geocoder-api';
import { GoogleMapsPlacesApi } from 'common/utils/google-maps-api/google-maps-places-api';
import { getGeoCodingLocation } from 'common/utils/google-places';
import MapFooter from 'common/components/maps/MapFooter/MapFooter';
import {
    selectAveragePriceOffer,
    selectFetchRouteRequest,
    selectMapSettings,
    selectPolylines,
    selectShipperLaneDestination,
    selectShipperLaneOrigin,
} from 'common/store/shipper-contract-lane-creation/selectors';
import { setDestinationLocation, setOriginLocation } from 'common/store/shipper-contract-lane-creation/slice';
import MapArea, { MapAreaThemeEnum } from 'common/components/maps/MapArea/MapArea';
import { M_IN_KM } from 'common/utils/distance';
import { useTranslation } from 'react-i18next';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import MapZoom from 'common/components/maps/MapZoom/MapZoom';
import MapCenter from 'common/components/maps/MapCenter/MapCenter';
import MapBound from 'common/components/maps/MapBound/MapBound';
import { SyncShipperContractLaneCreationFormMapStateContext } from '../contexts/sync-map-state';

const cx = classNames.bind(styles);

const MAP_PIN_KEYS = {
    origin: 'origin',
    destination: 'destination',
};

type PropsT = {
    isDisabledDraggable?: boolean;
    isAllowShowLoaders?: boolean;
};

const geocodeByPoint = async (
    googleMapContext: GoogleMapContextT | null,
    point: GeoPointT,
): Promise<LocationT | null> => {
    const geocoderApi = new GoogleMapsGeocoderApi(googleMapContext?.googleMaps?.maps);
    const geocodeResult = await geocoderApi.geocode(point);
    if (!geocodeResult?.place_id) {
        return null;
    }

    const placesApi = new GoogleMapsPlacesApi(googleMapContext?.googleMaps?.map, googleMapContext?.googleMaps?.maps);
    const result = await placesApi.getPlaceDetails(geocodeResult?.place_id);
    if (!result) {
        return null;
    }

    const location = getGeoCodingLocation(result);
    return location || null;
};

const ShipperContractLaneCreationMap: React.FC<PropsT> = React.memo((props) => {
    const { isDisabledDraggable, isAllowShowLoaders } = props;

    const mapSettings = useSelector(selectMapSettings);
    const dispatch = useDispatch();

    const syncFormMapStateContext = React.useContext(SyncShipperContractLaneCreationFormMapStateContext);

    const googleMapContext = React.useContext(GoogleMapContext);

    const { t } = useTranslation();

    const fetchRouteRequest = useSelector(selectFetchRouteRequest);
    const averagePriceOffer = useSelector(selectAveragePriceOffer);
    const isShowEstimate = !!averagePriceOffer;

    const polylines = useSelector(selectPolylines);

    const draggablePinRef = React.useRef<string | null>(null);
    const [activeLocation, setActiveLocation] = React.useState<LocationT | null>(null);

    const destination = useSelector(selectShipperLaneDestination);
    const origin = useSelector(selectShipperLaneOrigin);

    const [draggable, setDraggable] = React.useState(true);

    const handleDragMove = (childKey: string, childProps: any, mouse: GeoPointT) => {
        if (isDisabledDraggable) {
            return;
        }

        if (draggable) {
            setDraggable(false);
        }

        draggablePinRef.current = childKey;
        setActiveLocation({
            point: {
                lat: mouse.lat,
                lng: mouse.lng,
            },
            address: null,
            addressComponents: null,
            utcOffsetMinutes: null,
        });
    };

    const dispatchChangeOriginLocation = (location: LocationT | null) => {
        dispatch(setOriginLocation({ location }));
    };

    const dispatchChangeDestinationLocation = (location: LocationT | null) => {
        dispatch(setDestinationLocation({ location }));
    };

    const handleDragEnd = async (childKey: string, childProps: any, mouse: GeoPointT) => {
        if (!draggablePinRef.current) {
            return;
        }

        setDraggable(true);

        const location = await geocodeByPoint(googleMapContext, mouse);
        if (!location) {
            setActiveLocation({
                point: {
                    lat: mouse.lat,
                    lng: mouse.lng,
                },
                address: null,
                addressComponents: null,
                utcOffsetMinutes: null,
            });

            logWarning('empty location, failed geocodeByPoint', mouse);
            return;
        }

        if (draggablePinRef.current === MAP_PIN_KEYS.destination) {
            dispatchChangeDestinationLocation(location);
        }

        if (draggablePinRef.current === MAP_PIN_KEYS.origin) {
            dispatchChangeOriginLocation(location);
        }
    };

    const handleMapClick = async (event: ClickEventValue) => {
        if (isDisabledDraggable) {
            return;
        }

        if (origin.location && destination?.location) {
            return;
        }

        const location = await geocodeByPoint(googleMapContext, event);
        if (!origin.location) {
            dispatchChangeOriginLocation(location);
        } else {
            dispatchChangeDestinationLocation(location);
        }
    };

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const destinationLatLngProps = useMemo(() => {
        let latLngProps: GeoPointT | null = null;

        if (draggablePinRef.current === MAP_PIN_KEYS.destination) {
            if (!activeLocation?.point) {
                return null;
            }

            latLngProps = {
                lat: activeLocation.point.lat,
                lng: activeLocation.point.lng,
            };
        } else {
            if (!destination?.location?.point) {
                return null;
            }

            latLngProps = {
                lat: destination.location.point.lat,
                lng: destination.location.point.lng,
            };
        }

        return latLngProps;
    }, [draggablePinRef.current, activeLocation, destination]);

    const originLatLngProps = useMemo(() => {
        let latLngProps: GeoPointT | null = null;

        if (draggablePinRef.current === MAP_PIN_KEYS.origin) {
            if (!activeLocation?.point) {
                return null;
            }

            latLngProps = {
                lat: activeLocation.point.lat,
                lng: activeLocation.point.lng,
            };
        } else {
            if (!origin?.location?.point) {
                return null;
            }

            latLngProps = {
                lat: origin.location.point.lat,
                lng: origin.location.point.lng,
            };
        }

        return latLngProps;
    }, [draggablePinRef.current, activeLocation, origin]);

    return (
        <div className={cx('map')}>
            <GoogleMapReact
                draggable={draggable}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                onClick={handleMapClick}
                options={MAP_OPTIONS}
                onChildMouseDown={handleDragMove}
                onChildMouseUp={handleDragEnd}
                onChildMouseMove={handleDragMove}
                onGoogleApiLoaded={apiIsLoaded}
            >
                {destinationLatLngProps && (
                    <DestinationPinIcon
                        key={MAP_PIN_KEYS.destination}
                        className={cx('marker')}
                        {...destinationLatLngProps}
                        isWrong={syncFormMapStateContext.hasDropOffLocationError}
                    />
                )}

                {originLatLngProps && (
                    <OriginPinIcon
                        key={MAP_PIN_KEYS.origin}
                        className={cx('marker')}
                        {...originLatLngProps}
                        isWrong={syncFormMapStateContext.hasPickUpLocationError}
                    />
                )}
            </GoogleMapReact>
            <MapZoom
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                zoom={mapSettings?.zoom}
            />
            <MapCenter
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                center={mapSettings?.center}
            />
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                boundingBox={mapSettings?.boundingBox}
            />
            {isAllowShowLoaders && fetchRouteRequest.loading && <MapLoader>{t('common:maps.loading')}</MapLoader>}
            {polylines && (
                <MapRoute
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                    theme={MapRouteThemeEnum.laneCreation}
                    polylines={polylines}
                />
            )}
            {!!destination?.radiusKm && destinationLatLngProps && (
                <MapArea
                    key={`${MAP_PIN_KEYS.destination}-area`}
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    theme={MapAreaThemeEnum.destinationArea}
                    centerLat={destinationLatLngProps.lat}
                    centerLon={destinationLatLngProps.lng}
                    radiusM={destination.radiusKm * M_IN_KM}
                />
            )}
            {!!origin?.radiusKm && originLatLngProps && (
                <MapArea
                    key={`${MAP_PIN_KEYS.origin}-area`}
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    theme={MapAreaThemeEnum.originArea}
                    centerLat={originLatLngProps.lat}
                    centerLon={originLatLngProps.lng}
                    radiusM={origin.radiusKm * M_IN_KM}
                />
            )}
            {isShowEstimate && (
                <MapFooter>
                    <Estimate className={cx('estimate')} />
                </MapFooter>
            )}
        </div>
    );
});

export default ShipperContractLaneCreationMap;
