import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT, RoutePointFieldsEnum, RoutePointTypeEnum } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';
import { ShipperByIdT } from 'common/store/shipper-suggest/models';
import { checkIsAvailableCountryForShip } from 'common/store/country-settings-dict/utils/check-location';

import { CountrySettingsByCodeT } from 'common/store/country-settings-dict/models';

const verifyLocation = (t: TFunction, location: LocationT | undefined | null): string | null => {
    if (!location) {
        return null;
    }

    if (Number.isNaN(location.utcOffsetMinutes)) {
        return t('common:new-order-form.errors.wrong-utc-offset');
    }

    if (
        !location.addressComponents?.zipCode &&
        !location.addressComponents?.city &&
        !location.addressComponents?.countryCode &&
        !location.addressComponents?.street1 &&
        !location.addressComponents?.street2
    ) {
        return t('common:new-order-form.errors.unsupported-area');
    }

    if (!location.addressComponents?.countryCode) {
        return t('common:new-order-form.errors.need-address-details.country-code');
    }

    if (!location.addressComponents?.city) {
        return t('common:new-order-form.errors.need-address-details.locality');
    }

    return null;
};

const REQUIRED_FIELDS = [
    FieldsEnum.trailerDictId,
    // FieldsEnum.clientDropOffTimeWindow,
    // FieldsEnum.clientPickUpTimeWindow,
] as const;

const BROKER_REQUIRED_FIELDS = [...REQUIRED_FIELDS, FieldsEnum.shipperId] as const;

const convertCountryCode = (dictCountryCode: string) => dictCountryCode.toUpperCase();

type DataT = {
    isBroker: boolean;
    shippersById: ShipperByIdT;
    countriesSettingsByCode: CountrySettingsByCodeT;
};

const validate = (t: TFunction, values: FormValuesT, data: DataT): FormErrorsT => {
    const { isBroker, shippersById, countriesSettingsByCode } = data;

    const errors: FormErrorsT = {
        ...checkNotEmpty(t, isBroker ? BROKER_REQUIRED_FIELDS : REQUIRED_FIELDS, values),
    };

    const excludedCountries = values[FieldsEnum.excludedCountries] || [];
    const excludedCountriesSet = new Set(excludedCountries.map(convertCountryCode));

    const routeFormValue = values[FieldsEnum.route];
    routeFormValue.forEach((routePointFormValue, routePointIndex) => {
        const isFirstRoutePointIndex = routePointIndex === 0;
        const isLastRoutePointIndex = routePointIndex === routeFormValue.length - 1;

        const addRoutePointError = (fieldName: RoutePointFieldsEnum, message: string) => {
            if (!errors[FieldsEnum.route]) {
                errors[FieldsEnum.route] = [];
            }

            if (!errors[FieldsEnum.route][routePointIndex]) {
                errors[FieldsEnum.route][routePointIndex] = {};
            }

            errors[FieldsEnum.route][routePointIndex][fieldName] = message;
        };

        const routePointLocation = routeFormValue[routePointIndex][RoutePointFieldsEnum.location];
        if (!routePointLocation) {
            addRoutePointError(RoutePointFieldsEnum.location, t('common:new-order-form.errors.required-select-item'));
        }

        const routePointLocationError = verifyLocation(t, routePointLocation);
        if (routePointLocation && routePointLocationError) {
            addRoutePointError(RoutePointFieldsEnum.location, routePointLocationError);
        }

        if (!checkIsAvailableCountryForShip(routePointLocation, countriesSettingsByCode)) {
            addRoutePointError(RoutePointFieldsEnum.location, t('common:new-order-form.errors.unsupported-country'));
        }

        if (excludedCountriesSet.has(routePointLocation?.addressComponents?.countryCode || '')) {
            let excludeLocationErrorMessage;

            if (routePointFormValue?.[RoutePointFieldsEnum.type] === RoutePointTypeEnum.driveThrough) {
                excludeLocationErrorMessage = t('common:new-order-form.errors.excluded-drive-thru-country');
            }

            if (routePointFormValue?.[RoutePointFieldsEnum.type] === RoutePointTypeEnum.pickupOrDelivery) {
                if (isFirstRoutePointIndex) {
                    excludeLocationErrorMessage = t('common:new-order-form.errors.excluded-origin-country');
                } else if (isLastRoutePointIndex) {
                    excludeLocationErrorMessage = t('common:new-order-form.errors.excluded-destination-country');
                } else {
                    excludeLocationErrorMessage = t('common:new-order-form.errors.excluded-pickup-delivery-country');
                }
            }

            if (excludeLocationErrorMessage) {
                addRoutePointError(RoutePointFieldsEnum.location, excludeLocationErrorMessage);
            }
        }
    });

    const shipperId = values[FieldsEnum.shipperId];
    if (isBroker && shipperId !== null) {
        const shipper = shippersById[shipperId] || null;

        if (shipper && !shipper?.billingAddressVerified) {
            errors[FieldsEnum.shipperId] = t('common:new-order-form.errors.shipper-has-not-verified-billing-address');
        }

        if (shipper && !shipper?.documentsVerified) {
            errors[FieldsEnum.shipperId] = t('common:new-order-form.errors.shipper-has-not-verified-documents');
        }

        if (shipper && !shipper?.addressVerified) {
            errors[FieldsEnum.shipperId] = t('common:new-order-form.errors.shipper-has-not-verified-legal-address');
        }
    }

    return errors;
};

export default validate;
