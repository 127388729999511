import React from 'react';

import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { EmissionClassEnum } from 'common/constants';
import EmissionIcon, { EmissionIconProps } from 'common/icons/EmissionIcon';
import { EMISSION_CLASS_MAP } from 'common/components/EmissionClassLabel/EmissionClassLabel';

type ValueT = EmissionClassEnum | null;

type PropsT = {
    placeholder?: string;
    value: ValueT;
    onChange: (value: ValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
};

type EmissionStandardOptionT = {
    label: string;
    value: ValueT;
};

const ALL_EMISSION_STANDARDS: EmissionClassEnum[] = [EmissionClassEnum.euro5, EmissionClassEnum.euro6];

const EmissionClassDropdown: React.FC<PropsT> = React.memo((props) => {
    const { value, placeholder, onChange, hasWarning, hasError, hasChanges, onBlur, onFocus, hasClearControl } = props;

    const options: Array<EmissionStandardOptionT> = React.useMemo(
        () =>
            ALL_EMISSION_STANDARDS.map((value) => ({
                label: EMISSION_CLASS_MAP[value],
                value,
            })),
        [],
    );

    return (
        <DropdownInput<EmissionStandardOptionT, EmissionClassEnum | null>
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            onSelect={onChange}
            renderOption={(option: EmissionStandardOptionT | null | undefined, placeholder) => {
                if (!option) {
                    return <span>{placeholder}</span>;
                }

                return <span>{option.label}</span>;
            }}
            getOptionValue={(option: EmissionStandardOptionT) => {
                return option.value;
            }}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            renderLeftIcon={(iconMeta) => <EmissionIcon {...EmissionIconProps.getControlProps(iconMeta)} />}
            onReset={() => {
                onChange(null);
            }}
            hasClearControl={hasClearControl}
        />
    );
});

export default EmissionClassDropdown;
