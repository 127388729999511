import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TrailerCell.scss';
import { useTranslation } from 'react-i18next';
import { DispatchT } from 'broker-admin/store/dispatches/models';
import TableCellLink from 'common/components/Table/cell-renderers/TableCellLink/TableCellLink';
import { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';

type PropsT = {
    dispatch: DispatchT;
    onOpenTrailerDetails: (companyId: CarrierIdT, trailerId: TrailerIdT) => void;
};

const cx = classNames.bind(styles);

const TrailerCell: React.FC<PropsT> = React.memo((props) => {
    const { dispatch, onOpenTrailerDetails } = props;

    const { t } = useTranslation();

    const { trailerModel, trailerId, trailerPlateNumber } = dispatch;

    return (
        <div>
            {trailerModel && <div className={cx('model')}>{trailerModel}</div>}
            {trailerId ? (
                <TableCellLink
                    className={cx('trailer-link')}
                    onClick={() => {
                        if (!dispatch.trailerCarrierId || !dispatch.trailerId) {
                            return;
                        }

                        onOpenTrailerDetails(dispatch.trailerCarrierId, dispatch.trailerId);
                    }}
                    size={LinkSizeEnum.medium}
                    theme={LinkThemeEnum.boldBrandDark}
                >
                    {trailerPlateNumber}
                </TableCellLink>
            ) : (
                <div className={cx('not-assigned')}>{t('orders.list.table.not-assigned')}</div>
            )}
        </div>
    );
});

export default TrailerCell;
