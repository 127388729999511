import React from 'react';
import { SyncShipperContractLaneCreationFormMapStateContextValueT } from '../hooks/usySyncMapState';

const SyncShipperContractLaneCreationFormMapStateContext =
    React.createContext<SyncShipperContractLaneCreationFormMapStateContextValueT>({
        hasPickUpLocationError: false,
        setHasPickUpLocationError: () => {
            // nothing
        },
        hasDropOffLocationError: false,
        setHasDropOffLocationError: () => {
            // nothing
        },
    });

export { SyncShipperContractLaneCreationFormMapStateContext };
