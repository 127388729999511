import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';

export type ResponseDataMapT = {
    [TranziitApiRequestErrorSubTypeEnum.accessDeniedToApi]: Api2.Definitions.ServerErrorResponse;
    [TranziitApiRequestErrorSubTypeEnum.accessDeniedToEntity]: Api2.Definitions.ServerErrorResponse;
    [TranziitApiRequestErrorSubTypeEnum.notFoundCompany]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.emailAlreadyExist]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.phoneAlreadyExist]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.canNotDowngradeLastAdminInTeam]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.canNotDeleteHimself]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.orderCreationExpiredRFQ]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_ExpiredPriceOffer]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_CapacityExcess]: Api2.Definitions.ShipmentValidationResponse;
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_Overweight]: Api2.Definitions.ShipmentValidationResponse;
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteNotFound]: Api2.Definitions.RouteErrorResponse;
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteWaypointNotLinkable]: Api2.Definitions.RouteErrorResponse;
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_ProhibitedCountry]: Api2.Definitions.RouteErrorResponse;
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_AlreadyCompletedRFQ]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.noRouteFound]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.successorHasNotActiveStatus]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.couldNotUnLockCompany]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.notAuthorizedUser]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.alreadyAssignedDrivers]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.firebaseTooManyResendInviteRequests]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.expiredContextPreviewReserve]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.failedArchiveInTransitDriver]: unknown;
    [TranziitApiRequestErrorSubTypeEnum.assignmentSelfCostPrediction]: unknown;
};

export const checkIsTranziitApiErrorResponse = <TSubType extends keyof ResponseDataMapT>(
    error: Error | null | undefined,
    type: TSubType,
): error is TranziitApiRequestError<ResponseDataMapT[TSubType]> => {
    if (!checkIsTranziitApiRequestError(error)) {
        return false;
    }

    return error.subType === type;
};
