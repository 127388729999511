import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerTrailersPage.scss';
import TrailersPageLayout from 'common/layouts/TrailersPageLayout/TrailersPageLayout';

const cx = classNames.bind(styles);

type PropsT = {
    onOpenUserDetails: (userId: UserIdT | null) => void;
};

const PartnerTrailersPage: React.FC<PropsT> = React.memo((props) => {
    const { onOpenUserDetails } = props;

    return <TrailersPageLayout onOpenUserDetails={onOpenUserDetails} />;
});

export default PartnerTrailersPage;
