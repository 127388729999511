import { EmissionClassEnum } from 'common/constants';

export const MAX_RADIUS_KM = 300;
export const MIN_VALID_FROM_VALID_TILL_DAYS = 7;

export enum FieldsEnum {
    trailerDictId = 'trailerDictId',
    emissionClass = 'emissionClass',

    pickUpLocation = 'pickUpLocation',
    pickUpLocationRadius = 'pickUpLocationRadius',

    dropOffLocation = 'dropOffLocation',
    dropOffLocationRadius = 'dropOffLocationRadius',

    validFrom = 'validFrom',
    validTill = 'validTill',

    layoverLimit = 'layoverLimit',
    ordersLimit = 'ordersLimit',

    externalLaneId = 'externalLaneId',

    includeServiceFee = 'includeServiceFee',
    includeTeamDrive = 'includeTeamDrive',
    useApplyRules = 'useApplyRules',
}

export type FormTouchedT = Partial<Record<FieldsEnum, boolean>>;

export type FormValuesT = {
    [FieldsEnum.trailerDictId]: TrailerDictIdT | null;
    [FieldsEnum.emissionClass]: EmissionClassEnum | null;

    [FieldsEnum.pickUpLocation]: LocationT | null;
    [FieldsEnum.pickUpLocationRadius]: string;

    [FieldsEnum.dropOffLocation]: LocationT | null;
    [FieldsEnum.dropOffLocationRadius]: string;

    [FieldsEnum.validFrom]: Date | null;
    [FieldsEnum.validTill]: Date | null;

    [FieldsEnum.layoverLimit]: string;
    [FieldsEnum.ordersLimit]: string;

    [FieldsEnum.externalLaneId]: string;

    [FieldsEnum.includeServiceFee]: boolean;
    [FieldsEnum.includeTeamDrive]: boolean;
    [FieldsEnum.useApplyRules]: boolean;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
