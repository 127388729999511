import { EmissionClassEnum } from 'common/constants';

export enum FieldsEnum {
    keyAccountManagerId = 'keyAccountManagerId',
    keyAccountManagerName = 'keyAccountManagerName',

    dispatcherId = 'dispatcherId',
    dispatcherName = 'dispatcherName',

    shipperId = 'shipperId',
    shipperName = 'shipperName',
    carrierId = 'carrierId',
    carrierName = 'carrierName',

    emissionClass = 'emissionClass',
    dictTrailerId = 'dictTrailerId',

    pickupAddress = 'pickupAddress',
    dropoffAddress = 'dropoffAddress',

    createDates = 'createDates',

    pickupDates = 'pickupDates',
    pickupTimeWindow = 'pickupTimeWindow',
    dropoffDates = 'dropoffDates',
    dropoffTimeWindow = 'dropoffTimeWindow',
}

export type FormValuesT = {
    [FieldsEnum.keyAccountManagerId]: string | null;
    [FieldsEnum.keyAccountManagerName]: string | null;

    [FieldsEnum.dispatcherId]: string | null;
    [FieldsEnum.dispatcherName]: string | null;

    [FieldsEnum.shipperId]: string | null;
    [FieldsEnum.shipperName]: string | null;
    [FieldsEnum.carrierId]: string | null;
    [FieldsEnum.carrierName]: string | null;

    [FieldsEnum.emissionClass]: EmissionClassEnum | null;
    [FieldsEnum.dictTrailerId]: TrailerDictIdT | null;

    [FieldsEnum.pickupAddress]: string | null;
    [FieldsEnum.dropoffAddress]: string | null;

    [FieldsEnum.createDates]: DateRangeT | null;

    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.pickupTimeWindow]: TimeWindowT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;
    [FieldsEnum.dropoffTimeWindow]: TimeWindowT | null;
};
