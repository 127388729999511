import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './RouteEditingMap.scss';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectReserve,
    selectReservePreview,
    selectReservePreviewRequest,
    selectReserveRequest,
} from 'common/store/order-creation/selectors';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import { changeReserveQuery } from 'common/store/order-creation/actions';
import GoogleMapContext, { GoogleMapContextT } from 'common/contexts/google-map-context';
import MapFooterEstimate from 'common/layouts/NewOrderPage/MapFooterEstimate/MapFooterEstimate';
import ExcludeCountries from 'common/layouts/NewOrderPage/RouteEditingMap/ExcludeCountries/ExcludeCountries';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import { StyleGuideColorsEnum } from 'common/constants';
import { GoogleMapsGeocoderApi } from 'common/utils/google-maps-api/google-maps-geocoder-api';
import { GoogleMapsPlacesApi } from 'common/utils/google-maps-api/google-maps-places-api';
import { getGeoCodingLocation } from 'common/utils/google-places';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { isNonNil } from 'common/utils';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import { SyncShipmentDetailsFormMapStateContext } from '../ShipmentDetailsForm/contexts/sync-map-state';
import { SyncShipmentDetailsFormValuesContext } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/contexts/sync-form-values';
import { SyncMapSettingsCallbackT } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/usy-sync-map-state';
import NumberPinIcon from 'common/icons/NumberPinIcon';
import {
    FieldsEnum,
    RoutePointFieldsEnum,
    RoutePointFormValuesT,
    RoutePointTypeEnum,
} from 'common/layouts/NewOrderPage/ShipmentDetailsForm/constants';
import { getRoutePointFieldName } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/utils';
import findIndex from 'lodash/findIndex';
import { prepareApiReserveChangesChangeRoutePoint } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/prepare-api-requests';

const cx = classNames.bind(styles);

type PropsT = {};

const geocodeByPoint = async (
    googleMapContext: GoogleMapContextT | null,
    point: GeoPointT,
): Promise<LocationT | null> => {
    const geocoderApi = new GoogleMapsGeocoderApi(googleMapContext?.googleMaps?.maps);
    const geocodeResult = await geocoderApi.geocode(point);
    if (!geocodeResult?.place_id) {
        return null;
    }

    const placesApi = new GoogleMapsPlacesApi(googleMapContext?.googleMaps?.map, googleMapContext?.googleMaps?.maps);
    const result = await placesApi.getPlaceDetails(geocodeResult?.place_id);
    if (!result) {
        return null;
    }

    const location = getGeoCodingLocation(result);
    return location || null;
};

const tryGeocodeByPoint = async (googleMapContext: GoogleMapContextT | null, point: GeoPointT): Promise<LocationT> => {
    const geocodedLocation = await geocodeByPoint(googleMapContext, point);
    if (geocodedLocation) {
        return geocodedLocation;
    }

    return {
        point: {
            lat: point.lat,
            lng: point.lng,
        },
        address: `${point.lat}, ${point.lng}`,
        addressComponents: null,
        utcOffsetMinutes: null,
    };
};

const KEY_SPREADER = '/';
const KEY_PREFIX = 'route-point';

const formatKey = (routePointIndex: number): string => {
    return [KEY_PREFIX, routePointIndex].join(KEY_SPREADER);
};

const parseKey = (key: string): number => {
    const parts = key.split(KEY_SPREADER);

    return parseInt(parts[parts.length - 1], 10);
};

const RouteEditingMap: React.FC<PropsT> = React.memo(() => {
    const dispatch = useDispatch();

    const syncShipmentDetailsFormValuesContext = React.useContext(SyncShipmentDetailsFormValuesContext);
    const routeFormValue = syncShipmentDetailsFormValuesContext?.formValues?.[FieldsEnum.route];

    const syncShipmentDetailsFormMapStateContext = React.useContext(SyncShipmentDetailsFormMapStateContext);
    const googleMapContext = React.useContext(GoogleMapContext);

    const syncMapSettingsCallback = React.useCallback<SyncMapSettingsCallbackT>(
        (mapSettings) => {
            if (!googleMapContext.googleMaps) {
                return;
            }

            const { map, maps } = googleMapContext.googleMaps;
            if (!map || !maps) {
                return;
            }

            const { boundingBox, zoom, center } = mapSettings;

            if (boundingBox) {
                const point1 = new maps.LatLng(...boundingBox[0]);
                const point2 = new maps.LatLng(...boundingBox[1]);
                const bounds = new maps.LatLngBounds(point1, point2);
                map.fitBounds(bounds);
            }

            if (zoom) {
                map.setZoom(zoom);
            }

            if (center) {
                const centerLatLng = new maps.LatLng(center.lat, center.lng);
                map.setCenter(centerLatLng);
            }
        },
        [googleMapContext.googleMaps],
    );

    React.useEffect(() => {
        if (!googleMapContext.googleMaps) {
            return;
        }

        const { map, maps } = googleMapContext.googleMaps;
        if (!map || !maps) {
            return;
        }

        syncShipmentDetailsFormMapStateContext?.setIsMapInitialized(true);
    }, [googleMapContext.googleMaps, syncShipmentDetailsFormMapStateContext?.setIsMapInitialized]);

    const reserve = useSelector(selectReserve);
    const reservePreview = useSelector(selectReservePreview);

    const draggablePinRef = React.useRef<string | null>(null);
    const [activeLocation, setActiveLocation] = React.useState<LocationT | null>(null);
    const [draggable, setDraggable] = React.useState(true);

    const isEditing = syncShipmentDetailsFormMapStateContext?.editingRoutePointIndex !== null;

    const hasRedBorder =
        syncShipmentDetailsFormMapStateContext?.editingRoutePointIndex !== null &&
        syncShipmentDetailsFormMapStateContext?.hasEditingRoutePointError;

    const handleDragMove = (childKey: string, childProps: any, mouse: GeoPointT) => {
        setDraggable(false);

        draggablePinRef.current = childKey;

        setActiveLocation({
            point: {
                lat: mouse.lat,
                lng: mouse.lng,
            },
            address: null,
            addressComponents: null,
            utcOffsetMinutes: null,
        });
    };

    React.useEffect(() => {
        setActiveLocation(null);
        draggablePinRef.current = null;
    }, [routeFormValue]);

    const handleDragEnd = async (childKey: string, childProps: any, mouse: GeoPointT) => {
        if (draggablePinRef.current !== childKey) {
            return;
        }

        setDraggable(true);

        const location = await tryGeocodeByPoint(googleMapContext, mouse);

        const routePointIndex = parseKey(childKey);

        if (syncShipmentDetailsFormValuesContext?.setFieldValueCallback) {
            syncShipmentDetailsFormValuesContext.setFieldValueCallback(
                getRoutePointFieldName(routePointIndex, RoutePointFieldsEnum.location),
                location,
            );
        }

        dispatch(
            changeReserveQuery(
                prepareApiReserveChangesChangeRoutePoint(routePointIndex, {
                    [RoutePointFieldsEnum.location]: location,
                }),
            ),
        );
    };

    const handleMapClick = async (event: ClickEventValue) => {
        const location = await tryGeocodeByPoint(googleMapContext, event);

        let editingRoutePointIndex = -1;

        if (syncShipmentDetailsFormMapStateContext?.editingRoutePointIndex !== null) {
            editingRoutePointIndex = syncShipmentDetailsFormMapStateContext.editingRoutePointIndex;
        } else {
            editingRoutePointIndex = findIndex(routeFormValue, (routePointFormValue) => {
                const location = routePointFormValue[RoutePointFieldsEnum.location];

                return !location;
            });
        }

        if (editingRoutePointIndex !== -1) {
            if (syncShipmentDetailsFormValuesContext?.setFieldValueCallback) {
                syncShipmentDetailsFormValuesContext.setFieldValueCallback(
                    getRoutePointFieldName(editingRoutePointIndex, RoutePointFieldsEnum.location),
                    location,
                );
            }

            dispatch(
                changeReserveQuery(
                    prepareApiReserveChangesChangeRoutePoint(editingRoutePointIndex, {
                        [RoutePointFieldsEnum.location]: location,
                    }),
                ),
            );
        }
    };

    React.useEffect(() => {
        if (syncShipmentDetailsFormMapStateContext?.setSyncMapSettings) {
            syncShipmentDetailsFormMapStateContext.setSyncMapSettings(syncMapSettingsCallback);
        }
    }, [syncMapSettingsCallback]);

    React.useEffect(() => {
        if (syncShipmentDetailsFormMapStateContext?.initialBoundingBox) {
            syncMapSettingsCallback({
                center: null,
                zoom: null,
                boundingBox: syncShipmentDetailsFormMapStateContext.initialBoundingBox,
            });
        }
    }, [syncShipmentDetailsFormMapStateContext?.initialBoundingBox]);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const renderRoutePointPin = (
        routePointFormValue: RoutePointFormValuesT,
        routePointIndex: number,
    ): React.ReactNode => {
        const key = formatKey(routePointIndex);

        const location = routePointFormValue[RoutePointFieldsEnum.location];
        const locationType = routePointFormValue[RoutePointFieldsEnum.type];

        let latLngProps = {};
        if (draggablePinRef.current === key) {
            if (!activeLocation?.point) {
                return null;
            }

            latLngProps = {
                lat: activeLocation.point.lat,
                lng: activeLocation.point.lng,
            };
        } else {
            if (!location?.point) {
                return null;
            }

            latLngProps = {
                lat: location.point.lat,
                lng: location.point.lng,
            };
        }

        let fillColor = StyleGuideColorsEnum.charcoal;
        if (syncShipmentDetailsFormMapStateContext?.editingRoutePointErrorsIndexes.includes(routePointIndex)) {
            fillColor = StyleGuideColorsEnum.tomatoRed;
        } else if (syncShipmentDetailsFormMapStateContext?.editingRoutePointIndex === routePointIndex) {
            fillColor = StyleGuideColorsEnum.brandDark;
        } else if (locationType === RoutePointTypeEnum.pickupOrDelivery) {
            fillColor = StyleGuideColorsEnum.charcoal;
        } else if (locationType === RoutePointTypeEnum.driveThrough) {
            fillColor = StyleGuideColorsEnum.gray;
        }

        return (
            <NumberPinIcon
                number={routePointIndex + 1}
                key={key}
                className={cx('route-point-pin')}
                fillColor={fillColor}
                {...latLngProps}
            />
        );
    };

    const reserveRequest = useSelector(selectReserveRequest);
    const reservePreviewRequest = useSelector(selectReservePreviewRequest);

    const polylineIds = useMemo(() => {
        return [reserve?.polylineId].filter(isNonNil);
    }, [reserve?.polylineId]);

    useRouteGeometry(polylineIds);

    const reserveRouteGeometryState = useSelector(selectRoutingGeometryState(reserve?.polylineId));

    const isMapLoading =
        reserveRequest.loading || reservePreviewRequest.loading || reserveRouteGeometryState.requestStatus.loading;

    return (
        <div
            className={cx('map', {
                'map--isEditing': isEditing,
                'map--hasRedBorder': hasRedBorder,
            })}
        >
            <GoogleMapReact
                draggable={draggable}
                center={undefined}
                zoom={undefined}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                onClick={handleMapClick}
                options={MAP_OPTIONS}
                onChildMouseDown={handleDragMove}
                onChildMouseUp={handleDragEnd}
                onChildMouseMove={handleDragMove}
                onGoogleApiLoaded={apiIsLoaded}
            >
                {routeFormValue?.map((routePointFormValue, routePointIndex) => {
                    return renderRoutePointPin(routePointFormValue, routePointIndex);
                })}
            </GoogleMapReact>
            {!reserveRequest.loading && reserve && !reserveRouteGeometryState?.requestStatus?.loading && (
                <MapRoute
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                    theme={MapRouteThemeEnum.orderCreation}
                    polylines={reserveRouteGeometryState?.data}
                />
            )}
            {(isMapLoading || reserve) && (
                <MapFooterEstimate
                    isLoading={isMapLoading}
                    distanceM={reserve?.route?.distance}
                    travelTimeSec={reservePreview?.travelSeconds}
                />
            )}
            <ExcludeCountries className={cx('exclude-countries')} />
        </div>
    );
});

export default RouteEditingMap;
