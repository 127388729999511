import * as React from 'react';
import moment from 'moment';
import {
    AlertToastRendererT,
    CommonAlertTypeEnum,
    CommonAnyAlert,
} from 'common/components/toasts/AlertToastsManager/models';
import { AlertThemeEnum } from 'common/components/Alert/Alert';
import SquareInfoIcon from 'common/icons/SquareInfoIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { AnyAlert } from 'common/store/alerts/models';
import AddAccountDuotineIcon from 'common/icons/AddAccountDuotineIcon';
import DeactivateUserIcon from 'common/icons/DeactivateUserIcon';
import UserSettingsIcon from 'common/icons/UserSettingsIcon';
import { USER_ROLE_T_MAP } from 'common/utils/users';
import TrailerIcon from 'common/icons/TrailerIcon';
import StopWarningIcon from 'common/icons/StopWarningIcon';
import TruckIcon from 'common/icons/TruckIcon';
import UploadFileIcon from 'common/icons/UploadFileIcon';
import DeleteIcon from 'common/icons/DeleteIcon';
import DriverIcon from 'common/icons/DriverIcon';
import DoneIcon from 'common/icons/DoneIcon';
import { checkIsSameDay } from 'common/utils/time';
import AssetFailedActionFooter from 'common/components/toasts/footers/AssetFailedActionFooter/AssetFailedActionFooter';

const DATE_FORMAT = 'Do MMM';

const formatHMRDate = (date: string | null | undefined): string => {
    if (!date) {
        return '-';
    }

    return moment(date).format(DATE_FORMAT);
};

const checkIsCommonAnyAlert = (anyAlert: AnyAlert | null | undefined): anyAlert is CommonAnyAlert => {
    return anyAlert instanceof CommonAnyAlert;
};

export const renderCommonAlertToast: AlertToastRendererT = (t, anyAlert) => {
    if (!checkIsCommonAnyAlert(anyAlert)) {
        return null;
    }

    switch (anyAlert.payload.type) {
        case CommonAlertTypeEnum.userRestored: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <AddAccountDuotineIcon />,
                text: t('common:alerts.user-restored', {
                    fullName: anyAlert.payload.data.fullName,
                }),
            };
        }
        case CommonAlertTypeEnum.networkError: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <StopWarningIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.network-error'),
            };
        }
        case CommonAlertTypeEnum.inviteCanceled: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <DeactivateUserIcon />,
                text: t('common:alerts.invite-canceled', {
                    email: anyAlert.payload.data.email,
                }),
            };
        }
        case CommonAlertTypeEnum.contactAdded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <AddAccountDuotineIcon />,
                text: t('common:alerts.contact-added', {
                    fullName: anyAlert.payload.data.fullName,
                }),
            };
        }
        case CommonAlertTypeEnum.emailInvited: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <AddAccountDuotineIcon />,
                text: t('common:alerts.email-invited', {
                    email: anyAlert.payload.data.email,
                }),
            };
        }
        case CommonAlertTypeEnum.userRoleChanged: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <UserSettingsIcon />,
                text: t('common:alerts.user-role-changed', {
                    fullName: anyAlert.payload.data.fullName,
                    newRole: t(USER_ROLE_T_MAP[anyAlert.payload.data.newRole]),
                }),
            };
        }
        case CommonAlertTypeEnum.userDeactivated: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <DeactivateUserIcon />,
                text: t('common:alerts.user-deactivated', {
                    fullName: anyAlert.payload.data.fullName,
                }),
            };
        }
        case CommonAlertTypeEnum.contactDeleted: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <DeactivateUserIcon />,
                text: t('common:alerts.contact-deleted', {
                    fullName: anyAlert.payload.data.fullName,
                }),
            };
        }
        case CommonAlertTypeEnum.mainContactChanged: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <UserSettingsIcon />,
                text: t('common:alerts.main-contact-changed', {
                    fullName: anyAlert.payload.data.fullName,
                }),
            };
        }
        case CommonAlertTypeEnum.contactEdited: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <AddAccountDuotineIcon />,
                text: t('common:alerts.contact-edited', {
                    fullName: anyAlert.payload.data.fullName,
                }),
            };
        }
        case CommonAlertTypeEnum.userEdited: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <AddAccountDuotineIcon />,
                text: t('common:alerts.user-edited', {
                    fullName: anyAlert.payload.data.fullName,
                }),
            };
        }
        case CommonAlertTypeEnum.trailerAdded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-added', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trailerSuccessfullyActivated: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-activated', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trailerNotEditable: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <StopWarningIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.trailer-not-editable'),
            };
        }
        case CommonAlertTypeEnum.trailerSuccessfullyArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-archived', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trailersSuccessfullyActivated: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-successfully-activated', {
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.trailerSuccessfullyEdited: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-successfully-edited', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trailerSuccessfullySetUnavailable: {
            const isSameDay = checkIsSameDay(anyAlert.payload.data.fromDate, anyAlert.payload.data.toDate);

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <StopWarningIcon fillColor={StyleGuideColorsEnum.white} />,
                text: isSameDay
                    ? t('common:alerts.trailer-successfully-set-unavailable-one-date', {
                          plateNumber: anyAlert.payload.data.plateNumber,
                          date: formatHMRDate(anyAlert.payload.data.fromDate),
                      })
                    : t('common:alerts.trailer-successfully-set-unavailable', {
                          plateNumber: anyAlert.payload.data.plateNumber,
                          fromDate: formatHMRDate(anyAlert.payload.data.fromDate),
                          toDate: formatHMRDate(anyAlert.payload.data.toDate),
                      }),
            };
        }
        case CommonAlertTypeEnum.trailersSuccessfullyArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-successfully-archived', {
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.trailersFailedActivate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-failed-activate', {
                    count: anyAlert.payload.data.count,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.trailerFailedActivate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-failed-activate', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trailersFailedArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-failed-archived', {
                    count: anyAlert.payload.data.count,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.trailerFailedArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-failed-archived', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trailerSuccessfullyUpdatedContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.brandAccent,
                    icon: (
                        <TrailerIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.trailer-successfully-unset-contract', {
                        plateNumber: anyAlert.payload.data.plateNumber,
                    }),
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-successfully-change-contract', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                    contractName: anyAlert.payload.data.contractName,
                }),
            };
        }
        case CommonAlertTypeEnum.trailersSuccessfullyUpdatedContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.brandAccent,
                    icon: (
                        <TrailerIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.trailers-successfully-unset-contract', {
                        count: anyAlert.payload.data.count,
                    }),
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-successfully-change-contract', {
                    count: anyAlert.payload.data.count,
                    contractName: anyAlert.payload.data.contractName,
                }),
            };
        }
        case CommonAlertTypeEnum.trailersFailedUpdateContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.tomatoRed,
                    icon: (
                        <TrailerIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.trailers-failed-unset-contract', {
                        count: anyAlert.payload.data.count,
                    }),
                    footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-failed-change-contract', {
                    count: anyAlert.payload.data.count,
                    contractName: anyAlert.payload.data.contractName,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.trailerFailedUpdateContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.tomatoRed,
                    icon: (
                        <TrailerIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.trailer-failed-unset-contract', {
                        plateNumber: anyAlert.payload.data.plateNumber,
                    }),
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-failed-change-contract', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                    contractName: anyAlert.payload.data.contractName,
                }),
            };
        }
        case CommonAlertTypeEnum.trailerSuccessfullyUpdatedRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-successfully-change-rate', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trailersSuccessfullyUpdatedRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-successfully-change-rate', {
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.trailersFailedUpdateRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailers-failed-change-rate', {
                    count: anyAlert.payload.data.count,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.trailerFailedUpdateRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trailer-failed-change-rate', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.truckAdded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-added', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.truckSuccessfullyActivated: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-activated', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.truckSuccessfullyArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-archived', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trucksSuccessfullyActivated: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-successfully-activated', {
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.truckSuccessfullyEdited: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-successfully-edited', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.truckSuccessfullySetUnavailable: {
            const isSameDay = checkIsSameDay(anyAlert.payload.data.fromDate, anyAlert.payload.data.toDate);

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <StopWarningIcon fillColor={StyleGuideColorsEnum.white} />,
                text: isSameDay
                    ? t('common:alerts.truck-successfully-set-unavailable-one-date', {
                          plateNumber: anyAlert.payload.data.plateNumber,
                          date: formatHMRDate(anyAlert.payload.data.fromDate),
                      })
                    : t('common:alerts.truck-successfully-set-unavailable', {
                          plateNumber: anyAlert.payload.data.plateNumber,
                          fromDate: formatHMRDate(anyAlert.payload.data.fromDate),
                          toDate: formatHMRDate(anyAlert.payload.data.toDate),
                      }),
            };
        }
        case CommonAlertTypeEnum.trucksSuccessfullyArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-successfully-archived', {
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.trucksFailedActivate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-failed-activate', {
                    count: anyAlert.payload.data.count,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.truckFailedActivate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-failed-activate', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trucksFailedArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-failed-archived', {
                    count: anyAlert.payload.data.count,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.truckFailedArchived: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-failed-archived', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.truckSuccessfullyUpdatedContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.brandAccent,
                    icon: (
                        <TruckIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.truck-successfully-unset-contract', {
                        plateNumber: anyAlert.payload.data.plateNumber,
                    }),
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-successfully-change-contract', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                    contractName: anyAlert.payload.data.contractName,
                }),
            };
        }
        case CommonAlertTypeEnum.trucksSuccessfullyUpdatedContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.brandAccent,
                    icon: (
                        <TruckIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.trucks-successfully-unset-contract', {
                        count: anyAlert.payload.data.count,
                    }),
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-successfully-change-contract', {
                    count: anyAlert.payload.data.count,
                    contractName: anyAlert.payload.data.contractName,
                }),
            };
        }
        case CommonAlertTypeEnum.trucksFailedUpdateContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.tomatoRed,
                    icon: (
                        <TruckIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.trucks-failed-unset-contract', {
                        count: anyAlert.payload.data.count,
                    }),
                    footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-failed-change-contract', {
                    count: anyAlert.payload.data.count,
                    contractName: anyAlert.payload.data.contractName,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.truckFailedUpdateContract: {
            if (anyAlert.payload.data.contractName === null) {
                return {
                    id: anyAlert.id,
                    theme: AlertThemeEnum.tomatoRed,
                    icon: (
                        <TruckIcon
                            size={DEFAULT_ICON_SIZE}
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    ),
                    text: t('common:alerts.truck-failed-unset-contract', {
                        plateNumber: anyAlert.payload.data.plateNumber,
                    }),
                };
            }

            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-failed-change-contract', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                    contractName: anyAlert.payload.data.contractName,
                }),
            };
        }
        case CommonAlertTypeEnum.truckSuccessfullyUpdatedRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-successfully-change-rate', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.trucksSuccessfullyUpdatedRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-successfully-change-rate', {
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.trucksFailedUpdateRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.trucks-failed-change-rate', {
                    count: anyAlert.payload.data.count,
                }),
                footer: <AssetFailedActionFooter plateNumbers={anyAlert.payload.data.plateNumbers} />,
            };
        }
        case CommonAlertTypeEnum.truckFailedUpdateRate: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.truck-failed-change-rate', {
                    plateNumber: anyAlert.payload.data.plateNumber,
                }),
            };
        }
        case CommonAlertTypeEnum.truckNotEditable: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <StopWarningIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.truck-not-editable'),
            };
        }
        case CommonAlertTypeEnum.paperAdded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <UploadFileIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.paper-added'),
            };
        }
        case CommonAlertTypeEnum.paperDeleted: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <DeleteIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.paper-deleted'),
            };
        }
        case CommonAlertTypeEnum.driverAssigned: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DriverIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.drivers-assigned', {
                    postProcess: 'interval',
                    count: anyAlert.payload.data.driversCount,
                }),
            };
        }
        case CommonAlertTypeEnum.driverUnassigned: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <DriverIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.drivers-unassgined', {
                    postProcess: 'interval',
                    count: anyAlert.payload.data.driversCount,
                }),
            };
        }
        case CommonAlertTypeEnum.rfqSuccessfulClosed: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <DeleteIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.rfq-successful-closed'),
            };
        }
        case CommonAlertTypeEnum.spotAssetsAssigned: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.spot-assets-assigned'),
            };
        }
        case CommonAlertTypeEnum.spotAssetsUnassigned: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.white}
                        fillColor={StyleGuideColorsEnum.transparent}
                    />
                ),
                text: t('common:alerts.spot-assets-unassigned'),
            };
        }
        case CommonAlertTypeEnum.documentUploaded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.document-uploaded', {
                    name: anyAlert.payload.data.dictDocumentName,
                }),
            };
        }
        case CommonAlertTypeEnum.contractUploaded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.contract-uploaded'),
            };
        }
        case CommonAlertTypeEnum.documentApproved: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.document-approved', {
                    name: anyAlert.payload.data.dictDocumentName,
                }),
            };
        }
        case CommonAlertTypeEnum.documentRejected: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <StopWarningIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.document-rejected', {
                    name: anyAlert.payload.data.dictDocumentName,
                }),
            };
        }
        case CommonAlertTypeEnum.documentRevoked: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <StopWarningIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.document-revoked', {
                    name: anyAlert.payload.data.dictDocumentName,
                }),
            };
        }
        case CommonAlertTypeEnum.carrierSpotLanesUpdateSuccess: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.carrier-spot-lanes-updated', {
                    postProcess: 'interval',
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.carrierSpotLanesDeleteSuccess: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <DeleteIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.carrier-spot-lanes-deleted', {
                    postProcess: 'interval',
                    count: anyAlert.payload.data.count,
                }),
            };
        }
        case CommonAlertTypeEnum.shipperContractUploaded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.shipper-contract-uploaded'),
            };
        }
        case CommonAlertTypeEnum.shipperContractUpdated: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.shipper-contract-updated'),
            };
        }
        case CommonAlertTypeEnum.shipperContractRevoked: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <DeleteIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.shipper-contract-revoked'),
            };
        }
        case CommonAlertTypeEnum.shipperContractLaneRevoked: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.orange,
                icon: <DeleteIcon fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.shipper-contract-lane-revoked'),
            };
        }
        case CommonAlertTypeEnum.shipperContractLaneUpdated: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.shipper-contract-lane-updated'),
            };
        }
        case CommonAlertTypeEnum.shipperContractLaneAdded: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />,
                text: t('common:alerts.shipper-contract-lane-added'),
            };
        }
        default: {
            return null;
        }
    }

    return null;
};

export const getUnknownAlertToast: AlertToastRendererT = (t, anyAlert) => {
    return {
        id: anyAlert.id,
        theme: AlertThemeEnum.charcoal,
        icon: <SquareInfoIcon fillColor={StyleGuideColorsEnum.white} />,
        text: t('common:alerts.unknown'),
    };
};
