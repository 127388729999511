import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './SearchForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { useTranslation } from 'react-i18next';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import CountryFlagPlaceholderOption from 'common/components/dropdowns/options/CountryFlagPlaceholderOption/CountryFlagPlaceholderOption';
import { CountryCodeT } from 'common/store/countries-dict/models';
import isEqual from 'lodash/isEqual';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import CountryMultipleDropdown from 'common/components/dropdowns/CountryMultipleDropdown/CountryMultipleDropdown';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    initialFromCountryCodes: CountryCodeT[];
    initialToCountryCodes: CountryCodeT[];
    onChange: (fromCountryCodes: CountryCodeT[], toCountryCodes: CountryCodeT[]) => void;
};

const SearchForm: React.FC<PropsT> = (props) => {
    const { onChange, initialFromCountryCodes, initialToCountryCodes, className } = props;

    const { t } = useTranslation();

    const [fromCountryCodes, setFromCountryCodes] = React.useState<CountryCodeT[]>(initialFromCountryCodes);
    const [toCountryCodes, setToCountryCodes] = React.useState<CountryCodeT[]>(initialToCountryCodes);

    React.useEffect(() => {
        if (!isEqual(initialFromCountryCodes, fromCountryCodes)) {
            setFromCountryCodes(initialFromCountryCodes);
        }
        if (!isEqual(initialToCountryCodes, toCountryCodes)) {
            setToCountryCodes(initialToCountryCodes);
        }
    }, [initialFromCountryCodes, initialToCountryCodes]);

    React.useEffect(() => {
        onChange(fromCountryCodes, toCountryCodes);
    }, [fromCountryCodes, toCountryCodes]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const countryByCode = useSelector(selectCountriesByCode);

    const renderOption = (countryCode: CountryCodeT) => {
        return <CountyFlagLabel country={countryByCode[countryCode]} />;
    };

    const renderTrigger = (countryCodes: Array<CountryCodeT>) => {
        if (!countryCodes?.length) {
            return <CountryFlagPlaceholderOption label={t('common:not-selected')} />;
        }

        const countries = countryCodes.map((code) => countryByCode[code] || null);

        return <CountyFlagLabel countries={countries} />;
    };

    return (
        <div className={cs(cx('wrap'), className)}>
            <CountryMultipleDropdown
                className={cx('field', {
                    'field--from': true,
                })}
                values={fromCountryCodes}
                inputPlaceholder={t('price-settings.rate-matrix.fields.from.input-placeholder')}
                onChange={setFromCountryCodes}
                renderOption={renderOption}
                renderLeftIcon={() => null}
                renderRightIcon={() => null}
                renderTrigger={renderTrigger}
                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            />
            <CountryMultipleDropdown
                className={cx('field', {
                    'field--to': true,
                })}
                values={toCountryCodes}
                inputPlaceholder={t('price-settings.rate-matrix.fields.to.input-placeholder')}
                onChange={setToCountryCodes}
                renderOption={renderOption}
                renderLeftIcon={() => null}
                renderRightIcon={() => null}
                renderTrigger={renderTrigger}
                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            />
        </div>
    );
};

export default SearchForm;
