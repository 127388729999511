import { put, select, take, takeEvery } from 'redux-saga/effects';
import {
    AssignActionT,
    ASSIGNMENT_REQUEST,
    CHANGE_USER_SELECTION,
    ChangeUserSelectionActionT,
    FETCH_ASSET_SCHEDULE,
    FETCH_ASSIGN_DETAILS_REQUEST,
    FETCH_AVAILABLE_CARRIERS_REQUEST,
    FETCH_AVAILABLE_VEHICLES_REQUEST,
    FetchAssetScheduleActionT,
    FetchAssignDetailsActionT,
    FetchAvailableCarriersActionT,
    FetchAvailableVehiclesActionT,
    FIND_TRAILER_REQUEST,
    FIND_TRUCK_REQUEST,
    FindTrailerActionT,
    FindTruckActionT,
    INIT,
    INIT_USER_SELECTION,
    InitUserSelectionActionT,
    PREDICT_SELF_COST_REQUEST,
    PredictSelfCostActionT,
    REFRESH_TRAILER_REQUEST,
    REFRESH_TRUCK_REQUEST,
    RefreshTrailerActionT,
    RefreshTruckActionT,
    RESET,
} from './types';
import {
    assignBegin,
    assignError,
    assignSuccess,
    changeUserSelection,
    fetchAssetSchedule,
    fetchAssetScheduleBegin,
    fetchAssetScheduleError,
    fetchAssetScheduleSuccess,
    fetchAssignDetailsBegin,
    fetchAssignDetailsError,
    fetchAssignDetailsSuccess,
    fetchAvailableCarriers,
    fetchAvailableCarriersBegin,
    fetchAvailableCarriersError,
    fetchAvailableCarriersSuccess,
    fetchAvailableVehicles,
    fetchAvailableVehiclesBegin,
    fetchAvailableVehiclesError,
    fetchAvailableVehiclesSuccess,
    findTrailer,
    findTrailerBegin,
    findTrailerError,
    findTrailerSuccess,
    findTruck,
    findTruckBegin,
    findTruckError,
    findTruckSuccess,
    predictSelfCost,
    predictSelfCostBegin,
    predictSelfCostError,
    predictSelfCostSuccess,
    refreshTrailer,
    refreshTruck,
    setAssetSchedule,
    setEventLoadRoute,
} from './actions';
import {
    selectAssignmentRequestStatus,
    selectAvailableCarriersRequest,
    selectAvailableVehiclesQuery,
    selectScheduleAssetById,
    selectSelfCostPrediction,
    selectSelfCostPredictionQuery,
    selectTrailerAssignmentInfoById,
    selectTrailerSuggestQuery,
    selectTruckAssignmentInfoById,
    selectTruckSuggestQuery,
    selectUserSelection,
} from './selectors';
import { MS_IN_SEC } from 'common/utils/time';
import { logWarning } from 'common/utils/logger';
import { selectDispatchDetails } from 'broker-admin/store/dispatch-details/selectors';
import { fetchDispatchDetails } from 'broker-admin/store/dispatch-details/actions';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { getCurrentScheduleEvent } from 'broker-admin/store/dispatch-assigment/utils';
import { ApiCountriesT, VehicleScheduleEventTypeEnum } from 'common/utils/api/models';
import { dispatchesRefreshChannel } from 'broker-admin/store/dispatches/channels';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import checkNeedRequest from 'common/utils/check-need-request';
import { M_IN_KM } from 'common/utils/distance';
import { PointToDropT, SelfCostPredictionT, UserSelectionT } from 'broker-admin/store/dispatch-assigment/models';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';
import { isNonNil } from 'common/utils';
import uniq from 'lodash/uniq';
import { ALL_CARRIERS_ID } from 'common/constants';
import {
    selectCountrySettingsByCountryCode,
    selectCountrySettingsDictRequest,
} from 'common/store/country-settings-dict/selectors';
import { COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS } from 'common/store/country-settings-dict/types';
import { checkIsAvailableCountryForDropAsset } from 'common/store/country-settings-dict/utils/check-location';

const LIMIT_SUGGEST_RESULT = 50;

function* checkIsAvailableDropAssetPoints(
    pointToDropTruck: PointToDropT | null,
    pointToDropTrailer: PointToDropT | null,
): WrapGeneratorT<boolean> {
    const countriesSettingsRequest: ReturnType<typeof selectCountrySettingsDictRequest> = yield select(
        selectCountrySettingsDictRequest,
    );
    if (!countriesSettingsRequest?.ok) {
        yield take([COUNTRY_SETTINGS_DICT_REQUEST_SUCCESS]);
    }

    const countriesSettingsByCode: ReturnType<typeof selectCountrySettingsByCountryCode> = yield select(
        selectCountrySettingsByCountryCode,
    );
    const isAvailablePointToDropTrailer = pointToDropTrailer
        ? checkIsAvailableCountryForDropAsset(
              {
                  ...pointToDropTrailer,
                  utcOffsetMinutes: null,
              },
              countriesSettingsByCode,
          )
        : true;

    const isAvailablePointToDropTruck = pointToDropTruck
        ? checkIsAvailableCountryForDropAsset(
              {
                  ...pointToDropTruck,
                  utcOffsetMinutes: null,
              },
              countriesSettingsByCode,
          )
        : true;

    return isAvailablePointToDropTrailer && isAvailablePointToDropTruck;
}

function* initUserSelectionSaga(action: InitUserSelectionActionT): WrapGeneratorT<void> {
    const { dispatchId, changes } = action;

    if (
        dispatchId &&
        (changes.truckId || changes.trailerId) &&
        ('truckId' in changes ||
            'trailerId' in changes ||
            'excludedCountries' in changes ||
            'isIgnoreCertificates' in changes ||
            'isIgnoreEmissionStandard' in changes ||
            'pointToDropTruck' in changes ||
            'pointToDropTrailer' in changes)
    ) {
        yield put(
            predictSelfCost({
                dispatchId,
                truckId: changes.truckId || null,
                trailerId: changes.trailerId || null,
                excludedCountries: changes.excludedCountries || [],
                isIgnoreCertificates: changes.isIgnoreCertificates || null,
                isIgnoreEmissionStandard: changes.isIgnoreEmissionStandard || null,
                pointToDropTruck: changes.pointToDropTruck || null,
                pointToDropTrailer: changes.pointToDropTrailer || null,
            }),
        );
    }
}

function* changeUserSelectionSaga(action: ChangeUserSelectionActionT): WrapGeneratorT<void> {
    const { changes, dispatchId } = action;

    if (!dispatchId) {
        logWarning('Empty dispatchId');
        return;
    }

    const prevUserSelection: ReturnType<typeof selectUserSelection> = yield select(selectUserSelection);
    const userSelection: UserSelectionT = {
        ...prevUserSelection,
        ...changes,
    };

    if (
        dispatchId &&
        userSelection.radiusKm &&
        ('radiusKm' in changes ||
            'truckId' in changes ||
            'trailerId' in changes ||
            'carrierId' in changes ||
            'isIgnoreCertificates' in changes ||
            'isIgnoreEmissionStandard' in changes ||
            'pointToDropTrailer' in changes ||
            'pointToDropTruck' in changes)
    ) {
        yield put(
            fetchAvailableVehicles({
                dispatchId,
                radiusKm: userSelection.radiusKm,
                carrierId: userSelection.carrierId,
                truckId: userSelection.truckId,
                trailerId: userSelection.trailerId,
                isIgnoreCertificates: userSelection.isIgnoreCertificates,
                isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                pointToDropTruck: userSelection.pointToDropTruck,
                pointToDropTrailer: userSelection.pointToDropTrailer,
            }),
        );
    }

    const selfCostReserve: ReturnType<typeof selectSelfCostPrediction> = yield select(selectSelfCostPrediction);
    const isExpiredReserve = selfCostReserve?.expireTime ? selfCostReserve?.expireTime < Date.now() : false;
    if (
        dispatchId &&
        ('truckId' in changes ||
            'trailerId' in changes ||
            'excludedCountries' in changes ||
            'isIgnoreCertificates' in changes ||
            'isIgnoreEmissionStandard' in changes ||
            'pointToDropTruck' in changes ||
            'pointToDropTrailer' in changes ||
            isExpiredReserve)
    ) {
        yield put(
            predictSelfCost({
                dispatchId,
                truckId: userSelection.truckId,
                trailerId: userSelection.trailerId,
                excludedCountries: userSelection.excludedCountries,
                isIgnoreCertificates: userSelection.isIgnoreCertificates,
                isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                pointToDropTruck: userSelection.pointToDropTruck,
                pointToDropTrailer: userSelection.pointToDropTrailer,
            }),
        );
    }

    if (
        dispatchId &&
        userSelection.truckId &&
        ('isIgnoreCertificates' in changes ||
            'isIgnoreEmissionStandard' in changes ||
            'pointToDropTruck' in changes ||
            'pointToDropTrailer' in changes)
    ) {
        const truckAssignmentInfoById: ReturnType<typeof selectTruckAssignmentInfoById> = yield select(
            selectTruckAssignmentInfoById,
        );
        const truckAssignmentInfo = truckAssignmentInfoById[userSelection.truckId as TruckIdT];
        if (truckAssignmentInfo) {
            yield put(
                refreshTruck({
                    plateNumber: truckAssignmentInfo.plateNumber || '',
                    dispatchId,
                    trailerId: userSelection.trailerId,
                    isIgnoreCertificates: userSelection.isIgnoreCertificates,
                    isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                    pointToDropTruck: userSelection.pointToDropTruck,
                    pointToDropTrailer: userSelection.pointToDropTrailer,
                }),
            );
        }
    }

    if (
        dispatchId &&
        userSelection.trailerId &&
        ('isIgnoreCertificates' in changes ||
            'isIgnoreEmissionStandard' in changes ||
            'pointToDropTruck' in changes ||
            'pointToDropTrailer' in changes)
    ) {
        const trailerAssignmentInfoById: ReturnType<typeof selectTrailerAssignmentInfoById> = yield select(
            selectTrailerAssignmentInfoById,
        );
        const trailerAssignmentInfo = trailerAssignmentInfoById[userSelection.trailerId as TrailerIdT];
        if (trailerAssignmentInfo) {
            yield put(
                refreshTrailer({
                    dispatchId,
                    truckId: userSelection.truckId,
                    plateNumber: trailerAssignmentInfo.plateNumber || '',
                    isIgnoreCertificates: userSelection.isIgnoreCertificates,
                    isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                    pointToDropTruck: userSelection.pointToDropTruck,
                    pointToDropTrailer: userSelection.pointToDropTrailer,
                }),
            );
        }
    }

    if (userSelection.truckId && 'truckId' in changes) {
        yield put(fetchAssetSchedule(userSelection.truckId, dispatchId));
    }

    if (userSelection.trailerId && 'trailerId' in changes) {
        yield put(fetchAssetSchedule(userSelection.trailerId, dispatchId));
    }
}

function* fetchAssignDetailsSaga(action: FetchAssignDetailsActionT): WrapGeneratorT<void> {
    const { tourId, dispatchId } = action;

    yield put(fetchAssignDetailsBegin());

    const [error, details]: ReturnApiT<typeof brokerTranziitApi.fetchTourAssignDetails> =
        yield brokerTranziitApi.fetchTourAssignDetails(tourId);

    if (error) {
        yield put(fetchAssignDetailsError(error));
        return;
    }

    yield put(fetchAssignDetailsSuccess(details));

    // refresh if need
    const userSelection: ReturnType<typeof selectUserSelection> = yield select(selectUserSelection);
    yield put(changeUserSelection(dispatchId, userSelection));
}

function* fetchAvailableVehiclesSaga(action: FetchAvailableVehiclesActionT): WrapGeneratorT<void> {
    const { query } = action;

    const prevQuery: ReturnType<typeof selectAvailableVehiclesQuery> = yield select(selectAvailableVehiclesQuery);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    if (isSameQuery) {
        return;
    }

    const isAvailablePointToDrop = yield checkIsAvailableDropAssetPoints(
        query.pointToDropTrailer,
        query.pointToDropTruck,
    );
    if (!isAvailablePointToDrop) {
        logWarning('not available country for drop asset');
        return;
    }

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(
        selectDispatchDetails(query.dispatchId),
    );
    if (!dispatchDetails) {
        logWarning('empty dispatchDetails');
        return;
    }

    const tourId = findActualTour(dispatchDetails?.tours)?.id || null;
    if (!tourId) {
        logWarning('empty tourId');
        return;
    }

    yield put(fetchAvailableVehiclesBegin(query));

    const radiusM = query.radiusKm * M_IN_KM;

    const carrierIds: Array<CompanyIdT> = [];
    if (query.carrierId && query.carrierId !== ALL_CARRIERS_ID) {
        carrierIds.push(query.carrierId);
    }

    let selectedAsset: AssetIdT | undefined;
    if (query.truckId && !query.trailerId) {
        selectedAsset = query.truckId;
    }
    if (!query.truckId && query.trailerId) {
        selectedAsset = query.trailerId;
    }

    const [error, vehicles]: ReturnApiT<typeof brokerTranziitApi.searchAssetsForAssigment> =
        yield brokerTranziitApi.searchAssetsForAssigment(tourId, radiusM, {
            ignoreEmissionStandard: query.isIgnoreEmissionStandard || undefined,
            ignoreCertificates: query.isIgnoreCertificates || undefined,
            pointToDropTruck: query.pointToDropTruck?.point
                ? {
                      longitude: query.pointToDropTruck.point.lng,
                      latitude: query.pointToDropTruck.point.lat,
                  }
                : undefined,
            pointToDropTrailer: query.pointToDropTrailer?.point
                ? {
                      longitude: query.pointToDropTrailer.point.lng,
                      latitude: query.pointToDropTrailer.point.lat,
                  }
                : undefined,
            carrierIds: uniq(carrierIds),
            selectedAsset,
        });

    if (error) {
        yield put(fetchAvailableVehiclesError(query, error));
        return;
    }

    // randomizer for debug
    // vehicles.forEach((vehicle) => {
    //     vehicle.latitude += Math.random() * 3;
    //     vehicle.longitude += Math.random() * 3;
    // });

    /*
    // links for debug
    const firstTruck = vehicles?.find((vehicle) => vehicle.truck);
    const firstTrailer = vehicles?.find((vehicle) => vehicle.trailer);
    if (firstTruck && firstTrailer) {
        // @ts-ignore
        firstTruck.trailer = firstTrailer.trailer;
        // @ts-ignore
        firstTrailer.trailer.latitude += 0.1;
        // @ts-ignore
        firstTrailer.trailer.longitude += 0.1;
        // @ts-ignore
        firstTruck.trailer.carrierId = firstTruck.truck.carrierId;
        console.info('Added syntetic link!!!');
    }
     */

    yield put(fetchAvailableVehiclesSuccess(query, vehicles || []));
}

function* fetchAvailableCarriersSaga(action: FetchAvailableCarriersActionT): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectAvailableCarriersRequest> = yield select(
        selectAvailableCarriersRequest,
    );

    const isNeedRequest = checkNeedRequest(requestStatus, action.options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchAvailableCarriersBegin());

    const [error, carriers]: ReturnApiT<typeof brokerTranziitApi.fetchAvailableCarriers> =
        yield brokerTranziitApi.fetchAvailableCarriers();

    if (error) {
        yield put(fetchAvailableCarriersError(error));
        return;
    }

    yield put(fetchAvailableCarriersSuccess(carriers?.content || []));
}

function* refreshTruckSaga(action: RefreshTruckActionT): WrapGeneratorT<void> {
    yield put(findTruck(action.query));
}

function* findTruckSaga(action: FindTruckActionT): WrapGeneratorT<void> {
    const { query } = action;

    const prevQuery: ReturnType<typeof selectTruckSuggestQuery> = yield select(selectTruckSuggestQuery);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    if (isSameQuery) {
        return;
    }

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(
        selectDispatchDetails(query.dispatchId),
    );
    if (!dispatchDetails) {
        logWarning('empty dispatchDetails');
        return;
    }

    const tourId = findActualTour(dispatchDetails?.tours)?.id || null;
    if (!tourId) {
        logWarning('empty tourId');
        return;
    }

    const isAvailablePointToDrop = yield checkIsAvailableDropAssetPoints(
        query.pointToDropTrailer,
        query.pointToDropTruck,
    );
    if (!isAvailablePointToDrop) {
        logWarning('not available country for drop asset');
        return;
    }

    yield put(findTruckBegin(query));

    const [error, apiTrucksSuggestItems]: ReturnApiT<typeof brokerTranziitApi.findTrucksByPlateNumber> =
        yield brokerTranziitApi.findTrucksByPlateNumber(
            tourId,
            {
                trailerId: query.trailerId || undefined,
                plateNumber: query.plateNumber || undefined,
            },
            {
                ignoreEmissionStandard: query.isIgnoreEmissionStandard || undefined,
                ignoreCertificates: query.isIgnoreCertificates || undefined,
                pointToDropTruck: query.pointToDropTruck?.point
                    ? {
                          longitude: query.pointToDropTruck.point.lng,
                          latitude: query.pointToDropTruck.point.lat,
                      }
                    : undefined,
                pointToDropTrailer: query.pointToDropTrailer?.point
                    ? {
                          longitude: query.pointToDropTrailer.point.lng,
                          latitude: query.pointToDropTrailer.point.lat,
                      }
                    : undefined,
            },
        );

    if (error) {
        yield put(findTruckError(query, error));
        return;
    }

    const limitedTrucksSuggestItems = (apiTrucksSuggestItems || []).slice(0, LIMIT_SUGGEST_RESULT);

    yield put(findTruckSuccess(query, limitedTrucksSuggestItems));
}

function* refreshTrailerSaga(action: RefreshTrailerActionT): WrapGeneratorT<void> {
    yield put(findTrailer(action.query));
}

function* findTrailerSaga(action: FindTrailerActionT): WrapGeneratorT<void> {
    const { query } = action;

    const prevQuery: ReturnType<typeof selectTrailerSuggestQuery> = yield select(selectTrailerSuggestQuery);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    if (isSameQuery) {
        return;
    }

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(
        selectDispatchDetails(query.dispatchId),
    );
    if (!dispatchDetails) {
        logWarning('empty dispatchDetails');
        return;
    }

    const tourId = findActualTour(dispatchDetails?.tours)?.id || null;
    if (!tourId) {
        logWarning('empty tourId');
        return;
    }

    const isAvailablePointToDrop = yield checkIsAvailableDropAssetPoints(
        query.pointToDropTrailer,
        query.pointToDropTruck,
    );
    if (!isAvailablePointToDrop) {
        logWarning('not available country for drop asset');
        return;
    }

    yield put(findTrailerBegin(query));

    const [error, apiTrailersSuggestItems]: ReturnApiT<typeof brokerTranziitApi.findTrailersByPlateNumber> =
        yield brokerTranziitApi.findTrailersByPlateNumber(
            tourId,
            {
                truckId: query.truckId || undefined,
                plateNumber: query.plateNumber || undefined,
            },
            {
                ignoreEmissionStandard: query.isIgnoreEmissionStandard || undefined,
                ignoreCertificates: query.isIgnoreCertificates || undefined,
                pointToDropTruck: query.pointToDropTruck?.point
                    ? {
                          longitude: query.pointToDropTruck.point.lng,
                          latitude: query.pointToDropTruck.point.lat,
                      }
                    : undefined,
                pointToDropTrailer: query.pointToDropTrailer?.point
                    ? {
                          longitude: query.pointToDropTrailer.point.lng,
                          latitude: query.pointToDropTrailer.point.lat,
                      }
                    : undefined,
            },
        );

    if (error) {
        yield put(findTrailerError(query, error));
        return;
    }

    const limitedTrailersSuggestItems = (apiTrailersSuggestItems || []).slice(0, LIMIT_SUGGEST_RESULT);

    yield put(findTrailerSuccess(query, limitedTrailersSuggestItems));
}

function* predictSelfCostSaga(action: PredictSelfCostActionT): WrapGeneratorT<void> {
    const { query } = action;

    const selfCostReserve: ReturnType<typeof selectSelfCostPrediction> = yield select(selectSelfCostPrediction);
    const isExpiredReserve = selfCostReserve?.expireTime ? selfCostReserve?.expireTime < Date.now() : false;

    const prevQuery: ReturnType<typeof selectSelfCostPredictionQuery> = yield select(selectSelfCostPredictionQuery);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    if (isSameQuery && !isExpiredReserve) {
        return;
    }

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(
        selectDispatchDetails(query.dispatchId),
    );
    if (!dispatchDetails) {
        logWarning('empty dispatchDetails');
        return;
    }

    const tourId = findActualTour(dispatchDetails?.tours)?.id || null;
    if (!tourId) {
        logWarning('empty tourId');
        return;
    }

    yield put(predictSelfCostBegin(query));

    const isAvailablePointToDrop = yield checkIsAvailableDropAssetPoints(
        query.pointToDropTrailer,
        query.pointToDropTruck,
    );

    const isInvalidQuery = (query.truckId === null && query.trailerId === null) || !isAvailablePointToDrop;
    if (isInvalidQuery) {
        yield put(predictSelfCostSuccess(query, null));
        return;
    }

    const [error, result]: ReturnApiT<typeof brokerTranziitApi.calculateTourCostsForAssets> =
        yield brokerTranziitApi.calculateTourCostsForAssets(
            tourId,
            {
                truckId: query.truckId || undefined,
                trailerId: query.trailerId || undefined,
                prohibitedCountries: query.excludedCountries,
                withPolyLines: true,
            },
            {
                ignoreEmissionStandard: query.isIgnoreEmissionStandard || undefined,
                ignoreCertificates: query.isIgnoreCertificates || undefined,
                pointToDropTrailer: query.pointToDropTrailer?.point
                    ? {
                          longitude: query.pointToDropTrailer.point.lng,
                          latitude: query.pointToDropTrailer.point.lat,
                          country: query.pointToDropTrailer.addressComponents?.countryCode as ApiCountriesT,
                          zipCode: query.pointToDropTrailer.addressComponents?.zipCode || undefined,
                          city: query.pointToDropTrailer.addressComponents?.city || undefined,
                          street1: query.pointToDropTrailer.addressComponents?.street1 || undefined,
                          street2: query.pointToDropTrailer.addressComponents?.street2 || undefined,
                      }
                    : undefined,
                pointToDropTruck: query.pointToDropTruck?.point
                    ? {
                          longitude: query.pointToDropTruck.point.lng,
                          latitude: query.pointToDropTruck.point.lat,
                          country: query.pointToDropTruck.addressComponents?.countryCode as ApiCountriesT,
                          zipCode: query.pointToDropTruck.addressComponents?.zipCode || undefined,
                          city: query.pointToDropTruck.addressComponents?.city || undefined,
                          street1: query.pointToDropTruck.addressComponents?.street1 || undefined,
                          street2: query.pointToDropTruck.addressComponents?.street2 || undefined,
                      }
                    : undefined,
            },
        );

    if (error) {
        yield put(predictSelfCostError(query, error));
        return;
    }

    const preparedResult: SelfCostPredictionT = {
        ...result,
        expireTime: (result?.secondsToExpire || 0) * MS_IN_SEC + Date.now(),
    };

    yield put(predictSelfCostSuccess(query, preparedResult));
}

function* assignSaga(action: AssignActionT): WrapGeneratorT<void> {
    const { dispatchId, assignmentContextId } = action;

    const requestStatus: ReturnType<typeof selectAssignmentRequestStatus> = yield select(selectAssignmentRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(selectDispatchDetails(dispatchId));
    if (!dispatchDetails) {
        logWarning('empty dispatchDetails');
        return;
    }

    const tourId = findActualTour(dispatchDetails?.tours)?.id || null;
    if (!tourId) {
        logWarning('empty tourId');
        return;
    }

    yield put(assignBegin());

    const [error]: ReturnApiT<typeof brokerTranziitApi.assignOrder> = yield brokerTranziitApi.assignOrder(
        tourId,
        assignmentContextId,
    );
    if (error) {
        yield put(assignError(error));
    } else {
        yield put(assignSuccess());
    }

    yield put(fetchDispatchDetails(dispatchId, { isForceUpdate: true }));
    dispatchesRefreshChannel.emit({});
    stateMachineRefreshChannel.emit({});
}

function* fetchAssetScheduleSaga(action: FetchAssetScheduleActionT): WrapGeneratorT<void> {
    const { assetId, dispatchId } = action;

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(selectDispatchDetails(dispatchId));
    if (!dispatchDetails) {
        logWarning('empty dispatchDetails');
        return;
    }

    const firstWaypoint = findActualTour(dispatchDetails?.tours)?.waypoints?.[0] || null;
    const targetDate = firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;
    if (!targetDate) {
        logWarning('empty targetDate');
        return;
    }

    const scheduleAssetById = yield select(selectScheduleAssetById);
    const alreadyHasAssetSchedule = !!scheduleAssetById[assetId];
    if (alreadyHasAssetSchedule) {
        return;
    }

    yield put(fetchAssetScheduleBegin(assetId));

    const scheduleResponse: ReturnApiT<typeof brokerTranziitApi.fetchVehicleScheduleAroundDate> =
        yield brokerTranziitApi.fetchVehicleScheduleAroundDate(assetId, targetDate);

    const [error, data] = scheduleResponse;
    if (error) {
        yield put(fetchAssetScheduleError(assetId, error));
        return;
    }

    const schedule = data || [];

    const currentScheduleEvent = getCurrentScheduleEvent(assetId, schedule);
    const currentScheduleEventId = currentScheduleEvent?.id || null;

    yield put(setAssetSchedule(assetId, schedule, currentScheduleEventId));

    for (const scheduleEvent of schedule) {
        if (scheduleEvent?.eventType !== VehicleScheduleEventTypeEnum.shipment) {
            // eslint-disable-next-line no-continue
            continue;
        }

        const schedulePolylines: Array<GooglePolylineT> = [];

        const rfqRoutePolylineIds = [
            scheduleEvent?.dropTrailerPolylineId,
            scheduleEvent?.dropTruckPolylineId,
            scheduleEvent?.payloadPolylineId,
            scheduleEvent?.trailerToPickUpPolylineId,
            scheduleEvent?.truckToTrailerPolylineId,
        ].filter(isNonNil);

        for (const rfqRoutePolylineId of rfqRoutePolylineIds) {
            let fetchRouteGeometryPlainResponse: ReturnApiT<typeof commonTranziitApi.fetchRouteGeometryGoogle> | null;

            if (rfqRoutePolylineId) {
                fetchRouteGeometryPlainResponse = yield commonTranziitApi.fetchRouteGeometryGoogle(rfqRoutePolylineId);
            } else {
                yield put(
                    fetchAssetScheduleError(
                        assetId,
                        new Error(`empty polylineId for fetchRouteGeometryPlain ${JSON.stringify(rfqRoutePolylineId)}`),
                    ),
                );
                fetchRouteGeometryPlainResponse = null;
            }
            if (fetchRouteGeometryPlainResponse?.[0]) {
                yield put(fetchAssetScheduleError(assetId, fetchRouteGeometryPlainResponse[0]));
            }

            const polyline = fetchRouteGeometryPlainResponse?.[1];
            if (polyline) {
                schedulePolylines.push(...polyline);
            }
        }

        const scheduleEventId = scheduleEvent?.id || '';
        yield put(setEventLoadRoute(assetId, scheduleEventId, schedulePolylines));
    }

    yield put(fetchAssetScheduleSuccess(assetId));
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function* resetSaga(): WrapGeneratorT<void> {}

function* initAssignmentSaga(): WrapGeneratorT<void> {
    yield put(fetchAvailableCarriers());
}

function* dispatchAssigmentSaga(): WrapGeneratorT<void> {
    yield takeEvery(CHANGE_USER_SELECTION, changeUserSelectionSaga);
    yield takeEvery(INIT_USER_SELECTION, initUserSelectionSaga);
    yield takeEvery(FETCH_ASSIGN_DETAILS_REQUEST, fetchAssignDetailsSaga);
    yield takeEvery(FETCH_AVAILABLE_VEHICLES_REQUEST, fetchAvailableVehiclesSaga);
    yield takeEvery(FETCH_AVAILABLE_CARRIERS_REQUEST, fetchAvailableCarriersSaga);
    yield takeEvery(FIND_TRUCK_REQUEST, findTruckSaga);
    yield takeEvery(REFRESH_TRUCK_REQUEST, refreshTruckSaga);
    yield takeEvery(FIND_TRAILER_REQUEST, findTrailerSaga);
    yield takeEvery(REFRESH_TRAILER_REQUEST, refreshTrailerSaga);
    yield takeEvery(PREDICT_SELF_COST_REQUEST, predictSelfCostSaga);
    yield takeEvery(ASSIGNMENT_REQUEST, assignSaga);
    yield takeEvery(FETCH_ASSET_SCHEDULE, fetchAssetScheduleSaga);
    yield takeEvery(INIT, initAssignmentSaga);
    yield takeEvery(RESET, resetSaga);
}

export default dispatchAssigmentSaga;
