import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './RatesTable.scss';
import { useTranslation } from 'react-i18next';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import { RateT } from 'broker-admin/store/price-settings/rates/models';
import CheckboxHeader from 'common/components/Table/header-renderers/CheckboxHeader/CheckboxHeader';
import CheckboxCell from 'common/components/Table/cell-renderers/CheckboxCell/CheckboxCell';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import EditableCostCell from 'common/components/Table/cell-renderers/EditableCostCell/EditableCostCell';
import EventAuthorCell from 'common/components/Table/cell-renderers/EventAuthorCell/EventAuthorCell';
import CountryCell from 'common/components/Table/cell-renderers/CountryCell/CountryCell';

const cx = classNames.bind(styles);

type PropsT = {
    rates: Array<RateT>;
    isDisabled: boolean;
    isLoading: boolean;
    className?: string;
    selectedRatesSet: Set<RateT>;
    onSelectRates: (selectedRatesSet: Set<RateT>) => void;
    onUpdateRate: (id: RateT['id'], value: number) => void;
    onOpenUserDetails: (userId: UserIdT | null) => void;
};

const RatesTable: React.FC<PropsT> = React.memo((props) => {
    const {
        rates,
        className,
        selectedRatesSet,
        onSelectRates,
        onUpdateRate,
        onOpenUserDetails,
        isDisabled,
        isLoading,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<RateT, void>> = [
        {
            renderHeader: () => (
                <CheckboxHeader<RateT> selectedRowsSet={selectedRatesSet} rows={rates} onChange={onSelectRates} />
            ),
            headerClassName: cx('table__header', 'table__header--checkbox'),
            render: (rate: RateT) => (
                <CheckboxCell<RateT> row={rate} selectedRowsSet={selectedRatesSet} onChange={onSelectRates} />
            ),
            className: cx('table__column', 'table__column--checkbox'),
        },
        {
            renderHeader: () => <span>{t('price-settings.rate-matrix.table.columns.from')}</span>,
            headerClassName: cx('table__header', 'table__header--country'),
            render: (rate: RateT) => <CountryCell countryCode={rate.countryFrom} />,
            className: cx('table__column', 'table__column--country'),
        },
        {
            renderHeader: () => <span>{t('price-settings.rate-matrix.table.columns.to')}</span>,
            headerClassName: cx('table__header', 'table__header--country'),
            render: (rate: RateT) => <CountryCell countryCode={rate.countryTo} />,
            className: cx('table__column', 'table__column--country'),
        },
        {
            renderHeader: () => <span>{t('price-settings.rate-matrix.table.columns.cost')}</span>,
            headerClassName: cx('table__header', 'table__header--cost'),
            render: (rate: RateT, meta) => (
                <EditableCostCell
                    cost={rate.costKm}
                    isHoveredRow={meta.isHovered}
                    onUpdate={(cost) => {
                        onUpdateRate(rate.id, cost);
                    }}
                />
            ),
            className: cx('table__column', 'table__column--cost'),
        },
        {
            renderHeader: () => <span>{t('price-settings.rate-matrix.table.columns.update-date')}</span>,
            headerClassName: cx('table__header', 'table__header--date'),
            render: (rate: RateT) => <DateCell isBigFont isBold className={cx('update-date')} date={rate.updated} />,
            className: cx('table__column', 'table__column--date'),
        },
        {
            renderHeader: () => <span>{t('price-settings.rate-matrix.table.columns.update-author')}</span>,
            headerClassName: cx('table__header'),
            render: (rate: RateT) => (
                <EventAuthorCell
                    onOpenUserDetails={onOpenUserDetails}
                    userFullName={rate.updatedByName}
                    userId={rate.updatedById}
                />
            ),
            className: cx('table__column', 'table__column--update'),
        },
    ];

    const getRowMods = (meta: TableRowMetaT) => ({
        isSelected: meta.isSelected,
    });

    return (
        <div className={cx('table')}>
            <MemoizedTable<RateT> tableName="rates" isLoading={isLoading} rows={rates}>
                {(rows, isUsedPrevRows) => (
                    <Table<RateT, void>
                        className={className}
                        selectedRowsSet={selectedRatesSet}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
            {isDisabled && <LoaderOverlay />}
        </div>
    );
});

export default RatesTable;
