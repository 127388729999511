import * as React from 'react';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { APIFuelByCountryT, ApiTollByRoadTypeT } from 'common/utils/api/models';
import isNumber from 'lodash/isNumber';
import classNames from 'classnames/bind';
import styles from './ShipperPriceOfferInfoTable.scss';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import map from 'lodash/map';
import AsyncAdditionalServiceFormatter from 'design-system/components/InfoTable/formatters/AsyncAdditionalServiceFormatter/AsyncAdditionalServiceFormatter';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import FeeIcon from 'common/icons/FeeIcon';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';
import { checkShouldShowLayover } from 'common/components/PriceDetails/utils';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

export type ShipperPriceOfferInfoTablePropsT = {
    className?: string;
    hasContract?: boolean;
    totalPriceLabel: React.ReactNode;
    totalPriceTooltip?: React.ReactNode;
    totalPrice: number | undefined;
    lineHaulCost: number | undefined;
    teamDriveCost: number | undefined;
    tollCost: number | undefined;
    co2: number | undefined;
    tollByRoadType: ApiTollByRoadTypeT | undefined;
    tranziitServiceFee: number | undefined;
    urgentOverprice: number | undefined;
    layoverCost: number | undefined;
    layoverSeconds: number | undefined;
    fuelCost: number | undefined;
    fuelByCountry: APIFuelByCountryT | undefined;
    greenOverprice: number | undefined;
    additionalServicesCost: number | undefined;
    additionalServices:
        | Array<{
              enum: DictAdditionalServiceT['enum'];
              cost: DictAdditionalServiceT['cost'];
          }>
        | undefined;
    shouldAlwaysRenderExpandTrigger?: boolean;
};

const ShipperPriceOfferInfoTable: React.FC<ShipperPriceOfferInfoTablePropsT> = React.memo((props) => {
    const {
        className,
        hasContract,
        totalPrice,
        totalPriceLabel,
        totalPriceTooltip,
        lineHaulCost,
        teamDriveCost,
        tollCost,
        tollByRoadType,
        tranziitServiceFee,
        urgentOverprice,
        layoverCost,
        layoverSeconds,
        fuelCost,
        fuelByCountry,
        greenOverprice,
        additionalServicesCost,
        additionalServices,
        shouldAlwaysRenderExpandTrigger,
        co2,
    } = props;

    const { t } = useTranslation();

    const commonPriceDetails = useCommonPriceDetails();

    const additionalServicesCostList = map(additionalServices, (additionalService): PriceDetailT => {
        return {
            iconNode: null,
            title: <AsyncAdditionalServiceFormatter type={additionalService.enum} />,
            price: additionalService.cost,
        };
    });

    const priceDetails: Array<PriceDetailT | null> = [
        {
            iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
            title: totalPriceLabel,
            price: totalPrice,
            tooltipNode: totalPriceTooltip ? (
                <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                    {totalPriceTooltip}
                </TooltipContent>
            ) : null,
            list: hasContract
                ? []
                : [
                      {
                          ...commonPriceDetails.lineHaul,
                          price: lineHaulCost,
                      },
                      isNumber(teamDriveCost)
                          ? {
                                ...commonPriceDetails.teamDrive,
                                price: teamDriveCost,
                            }
                          : null,
                      // {
                      //     iconNode: null,
                      //     name: t('common:order-details.columns.equipment-cost'),
                      //     value: <Money amount={equipment} currency={CurrencyEnum.EUR} />,
                      //     emptyValue: t('common:info-table.placeholders.not-assigned'),
                      //     isBoldValue: true,
                      // },
                      {
                          ...commonPriceDetails.getRoadTaxes(co2),
                          price: tollCost,
                          list: commonPriceDetails.getRoadTaxesList(tollByRoadType),
                      },
                      {
                          ...commonPriceDetails.fuelCost,
                          price: fuelCost,
                          list: commonPriceDetails.getFuelCostsByCountryList(fuelByCountry),
                      },
                      {
                          ...commonPriceDetails.additionalServices,
                          price: additionalServicesCost || 0,
                          list: additionalServicesCostList?.length
                              ? [
                                    {
                                        list: additionalServicesCostList,
                                    },
                                ]
                              : undefined,
                      },
                      {
                          iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                          title: t('common:order-details.columns.tranziit-service-fee-cost'),
                          price: tranziitServiceFee,
                      },
                      isNumber(urgentOverprice)
                          ? {
                                ...commonPriceDetails.urgentOverprice,
                                price: urgentOverprice,
                            }
                          : null,
                      isNumber(greenOverprice)
                          ? {
                                ...commonPriceDetails.greenSurcharge,
                                price: greenOverprice,
                            }
                          : null,
                      checkShouldShowLayover(layoverSeconds, layoverCost)
                          ? {
                                ...commonPriceDetails.getLayoverCost(layoverSeconds),
                                price: layoverCost || 0,
                            }
                          : null,
                  ],
        },
    ].filter(isNonNil);

    return (
        <PriceDetails
            className={className}
            list={priceDetails}
            shouldAlwaysRenderExpandTrigger={shouldAlwaysRenderExpandTrigger}
        />
    );
});

export default ShipperPriceOfferInfoTable;
