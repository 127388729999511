import React from 'react';
import { SyncAssignmentFormMapStateContextValueT } from '../hooks/usySyncMapState';

const SyncAssignmentFormMapStateContext = React.createContext<SyncAssignmentFormMapStateContextValueT>({
    hasDropTrailerPointError: false,
    setHasDropTrailerPointError: () => {
        // nothing
    },
    hasDropTruckPointError: false,
    setHasDropTruckPointError: () => {
        // nothing
    },
});

export { SyncAssignmentFormMapStateContext };
