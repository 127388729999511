import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AssetCell.scss';
import { useTranslation } from 'react-i18next';
import { SpotRequestT } from 'broker-admin/store/spot-requests/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { LinkSizeEnum, LinkThemeEnum, LinkToT } from 'common/components/Link/Link';
import TableCellLink from 'common/components/Table/cell-renderers/TableCellLink/TableCellLink';

type PropsT = {
    spotRequest: SpotRequestT;
    getPartnerLink: (partnerType: PartnerTypeEnum, partnerId: CompanyIdT) => LinkToT;
};

const cx = classNames.bind(styles);

const AssetCell: React.FC<PropsT> = React.memo((props) => {
    const { spotRequest, getPartnerLink } = props;

    const { t } = useTranslation();

    const trailerTypeModel = spotRequest.trailerTypeModel || null;

    const carrierId = spotRequest.carrierId || null;

    return (
        <div>
            {trailerTypeModel && <div className={cx('model')}>{trailerTypeModel}</div>}
            {carrierId ? (
                <TableCellLink
                    to={getPartnerLink(PartnerTypeEnum.carrier, carrierId)}
                    theme={LinkThemeEnum.boldBrandDark}
                    size={LinkSizeEnum.medium}
                >
                    {spotRequest.carrierName}
                </TableCellLink>
            ) : (
                <div className={cx('not-assigned')}>{t('spot-requests.list.table.not-assigned')}</div>
            )}
        </div>
    );
});

export default AssetCell;
