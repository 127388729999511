import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchTrucksPageQueryT } from 'common/store/trucks/models';
import { TabEnum } from './constants';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { TrucksSortEnum, TruckStatusEnum } from 'common/utils/api/models';
import { PageSortT } from 'common/utils/query';
import { ALL_BROKER_USERS_VALUE } from 'common/components/dropdowns/UserMultipleDropdown/constants';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<TrucksSortEnum> | null,
    activeTab: TabEnum,
): FetchTrucksPageQueryT => {
    const statuses: TruckStatusEnum[] = [];
    const status = queryFilters?.[QueryFiltersKeysEnum.status] as Array<TruckStatusEnum>;
    if (status?.length) {
        statuses.push(...status);
    }

    let emissionClasses;
    if (queryFilters?.[QueryFiltersKeysEnum.emissionClass]) {
        emissionClasses = queryFilters[QueryFiltersKeysEnum.emissionClass];
    }

    const lastModifiedByUsers = queryFilters[QueryFiltersKeysEnum.lastModifyByUserId]?.filter((userId) => {
        return userId !== ALL_BROKER_USERS_VALUE;
    });

    const lastModifiedByBroker = !!queryFilters[QueryFiltersKeysEnum.lastModifyByUserId]?.some((userId) => {
        return userId === ALL_BROKER_USERS_VALUE;
    });

    const addedByUsers = queryFilters[QueryFiltersKeysEnum.createdByUserId]?.filter((userId) => {
        return userId !== ALL_BROKER_USERS_VALUE;
    });

    const addedByBroker = !!queryFilters[QueryFiltersKeysEnum.createdByUserId]?.some((userId) => {
        return userId === ALL_BROKER_USERS_VALUE;
    });

    return {
        truckModels: queryFilters?.[QueryFiltersKeysEnum.model] || undefined,
        plateNumber: queryFilters?.[QueryFiltersKeysEnum.plateNumber] || undefined,
        countriesOfRegistration: queryFilters?.[QueryFiltersKeysEnum.country] || undefined,
        contractIds: queryFilters?.[QueryFiltersKeysEnum.contractId] || undefined,
        emissionClasses,

        statuses,
        archived: activeTab === TabEnum.archive,

        createdDateFrom: queryFilters?.[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        createdDateTo: queryFilters?.[QueryFiltersKeysEnum.createdDateTo] || undefined,

        updatedDateFrom: queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom] || undefined,
        updatedDateTo: queryFilters[QueryFiltersKeysEnum.lastModifyDateTo] || undefined,

        telematicUpdatedDateFrom: queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateFrom] || undefined,
        telematicUpdatedDateTo: queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateTo] || undefined,

        addedByBroker: addedByBroker || undefined,
        addedByUsers: addedByUsers?.length ? addedByUsers : undefined,

        lastModifiedByBroker: lastModifiedByBroker || undefined,
        lastModifiedByUsers: lastModifiedByUsers?.length ? lastModifiedByUsers : undefined,

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
