import React, { useMemo } from 'react';

export type SyncShipperContractLaneCreationFormMapStateContextValueT = {
    hasPickUpLocationError: boolean;
    setHasPickUpLocationError: (hasPickUpLocationError: boolean) => void;
    hasDropOffLocationError: boolean;
    setHasDropOffLocationError: (hasDropOffLocationError: boolean) => void;
};

export const usySyncMapState = (): SyncShipperContractLaneCreationFormMapStateContextValueT => {
    const [hasPickUpLocationError, setHasPickUpLocationError] = React.useState<boolean>(false);
    const [hasDropOffLocationError, setHasDropOffLocationError] = React.useState<boolean>(false);

    return useMemo(() => {
        return {
            hasPickUpLocationError,
            setHasPickUpLocationError,
            hasDropOffLocationError,
            setHasDropOffLocationError,
        };
    }, [hasPickUpLocationError, hasDropOffLocationError]);
};
