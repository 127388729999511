import {
    ApiCreateReserveQueryT,
    CreateReserveQueryChangesT,
    CreateReserveQueryT,
    ReserveQueryChangesT,
    ReserveT,
} from '../models';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';
import { checkIsSameDay, setISODateMidday } from 'common/utils/time';
import isNumber from 'lodash/isNumber';
import { convertToPointLocation } from 'common/store/order-creation/utils/prepare-address-point';
import times from 'lodash/times';
import cloneDeep from 'lodash/cloneDeep';

const getReverseQueryChanges = (
    changes: ReserveQueryChangesT,
    prevReserveQuery: CreateReserveQueryT | null,
    reserve: ReserveT | null,
): CreateReserveQueryChangesT => {
    const queryChanges: CreateReserveQueryChangesT = {};

    if (changes?.fullRouteChange) {
        const addressQueryChanges: NonNullable<CreateReserveQueryChangesT['addresses']> = [];

        changes.fullRouteChange?.forEach((routePoint, index) => {
            if ('address' in routePoint || 'driveThru' in routePoint) {
                addressQueryChanges[index] = {
                    address: routePoint.address || null,
                    driveThru: !!routePoint.driveThru,
                };
            }
        });

        queryChanges.addresses = addressQueryChanges;
    }

    if (changes?.routePointChange) {
        const { index, changes: data } = changes.routePointChange;

        const newAddresses = cloneDeep(prevReserveQuery?.addresses || []);

        if (!newAddresses[index]) {
            newAddresses[index] = {
                address: data?.address || null,
                driveThru: !!data?.driveThru,
            };
        }

        if ('address' in data) {
            newAddresses[index].address = data.address || null;
        }

        if ('driveThru' in data) {
            newAddresses[index].driveThru = !!data?.driveThru;
        }

        queryChanges.addresses = newAddresses;
    }

    if (changes?.addRoutePoint) {
        const { index, changes: data } = changes.addRoutePoint;

        const newAddresses = cloneDeep(prevReserveQuery?.addresses || []);

        newAddresses.splice(index, 0, {
            address: data?.address || null,
            driveThru: !!data?.driveThru,
        });

        queryChanges.addresses = newAddresses;
    }

    if (changes?.deleteRoutePoint) {
        const { index } = changes.deleteRoutePoint;

        const newAddresses = cloneDeep(prevReserveQuery?.addresses || []);

        newAddresses.splice(index, 1);

        queryChanges.addresses = newAddresses;
    }

    if (!isUndefined(changes?.shipperId)) {
        queryChanges.shipperId = changes.shipperId;
    }

    const trailerId = Number(changes.trailerDictId);
    if (!isUndefined(changes.trailerDictId) && isNumber(trailerId)) {
        queryChanges.trailerDictId = trailerId;
    }

    if (!isUndefined(changes?.excludedCountries)) {
        queryChanges.prohibitedCountries = changes.excludedCountries;
    }

    let firstRoutePoint = changes?.fullRouteChange?.[0] || null;
    if (changes?.routePointChange && changes?.routePointChange?.index === 0) {
        firstRoutePoint = changes?.routePointChange?.changes || null;
    }
    if (changes?.addRoutePoint && changes?.addRoutePoint?.index === 0) {
        firstRoutePoint = changes?.addRoutePoint?.changes || null;
    }
    // TODO support changes?.deleteRoutePoint

    if (firstRoutePoint && !isNil(firstRoutePoint?.timeWindowFrom)) {
        const defaultPickUpTimeWindowStart = reserve?.points?.[0]?.defaultTimeWindow?.start;
        const isNotDefaultReservePickUpTime =
            defaultPickUpTimeWindowStart &&
            !checkIsSameDay(defaultPickUpTimeWindowStart, firstRoutePoint.timeWindowFrom);
        if (isNotDefaultReservePickUpTime) {
            queryChanges.routeStart = setISODateMidday(firstRoutePoint?.timeWindowFrom) || undefined;
        } else if (prevReserveQuery?.routeStart) {
            queryChanges.routeStart = undefined;
        }
    }

    return queryChanges;
};

const mergeReverseQueryChanges = (
    prevQuery: CreateReserveQueryT | null,
    changes: CreateReserveQueryChangesT,
): CreateReserveQueryT | null => {
    if (isEmpty(changes)) {
        return prevQuery;
    }

    const query: CreateReserveQueryT = {
        prohibitedCountries: [],
        shipperId: null,
        trailerDictId: null,
        ...prevQuery,
        ...changes,
        addresses: changes?.addresses || prevQuery?.addresses || [],
    };

    return query;
};

const getApiReverseQuery = (isBroker: boolean, query: CreateReserveQueryT | null): ApiCreateReserveQueryT | null => {
    if (!query) {
        return null;
    }

    const { addresses, prohibitedCountries, shipperId, routeStart, trailerDictId } = query;

    const hasAllAddresses =
        addresses?.length >= 2 && times(addresses?.length).every((_, index) => !!addresses[index]?.address);
    if (isBroker && !shipperId) {
        return null;
    }

    if (!isNumber(trailerDictId) || !hasAllAddresses) {
        return null;
    }

    return {
        addresses: addresses.reduce<ApiCreateReserveQueryT['addresses']>((acc, address) => {
            const pointLocation = convertToPointLocation(address.address);

            if (address.address && pointLocation) {
                acc.push({
                    address: pointLocation,
                    driveThru: address.driveThru,
                });
            }

            return acc;
        }, []),
        prohibitedCountries,
        shipperId,
        routeStart,
        dictTrailerId: trailerDictId,
    };
};

export { getReverseQueryChanges, mergeReverseQueryChanges, getApiReverseQuery };
