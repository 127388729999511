import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AssetFailedActionFooter.scss';
import CopyTextButton from 'common/components/CopyTextButton/CopyTextButton';
import { ButtonThemeEnum } from 'common/components/Button/Button';

const cx = classNames.bind(styles);

type PropsT = {
    plateNumbers: Array<string>;
};

const AssetFailedActionFooter: React.FC<PropsT> = (props) => {
    const { plateNumbers } = props;

    return (
        <div className={cx('footer')}>
            <CopyTextButton
                className={cx('copy-button')}
                theme={ButtonThemeEnum.secondary}
                textForClipboard={plateNumbers.join(',')}
            />
        </div>
    );
};

export default AssetFailedActionFooter;
