import { FieldsEnum, FormValuesT, RoutePointFieldsEnum, RoutePointFormValuesT, RoutePointTypeEnum } from './constants';
import { ApiActualOrderRequestT } from 'common/utils/api/models';
import { ReserveQueryChangesT, ReserveQueryRoutePointChangesT, ReserveT } from 'common/store/order-creation/models';
import { prepareLocation } from 'common/utils/prepare-location';
import { AvailableChangesT, AvailableRoutePointChangesT } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/models';

const prepareApiActualOrderRequest = (values: FormValuesT, reserve: ReserveT): ApiActualOrderRequestT | null => {
    const timeWindows: ApiActualOrderRequestT['timeWindows'] = [];

    reserve.points.forEach((point, index) => {
        const routePointFormValue = values[FieldsEnum.route]?.[index];

        const timeWindow = routePointFormValue?.[RoutePointFieldsEnum.timeWindow];
        if (timeWindow && !point.driveThru) {
            timeWindows.push({
                id: point.id,
                brokerWindow: routePointFormValue?.[RoutePointFieldsEnum.isBrokerTimeWindow],
                from: timeWindow.start,
                to: timeWindow.end,
            });
        }
    });

    return {
        timeWindows,
    };
};

const prepareApiReserveRoutePointChanges = (
    changes: Partial<RoutePointFormValuesT>,
): ReserveQueryRoutePointChangesT => {
    const apiRoutePointChanges: ReserveQueryRoutePointChangesT = {};

    if (RoutePointFieldsEnum.isBrokerTimeWindow in changes) {
        apiRoutePointChanges.isBrokerTimeWindow = changes[RoutePointFieldsEnum.isBrokerTimeWindow];
    }

    if (RoutePointFieldsEnum.timeWindow in changes) {
        const timeWindow = changes[RoutePointFieldsEnum.timeWindow];

        apiRoutePointChanges.timeWindowFrom = timeWindow?.start || null;
        apiRoutePointChanges.timeWindowTo = timeWindow?.end || null;
    }

    if (RoutePointFieldsEnum.type in changes) {
        apiRoutePointChanges.driveThru = changes[RoutePointFieldsEnum.type] === RoutePointTypeEnum.driveThrough;
    }

    if (RoutePointFieldsEnum.location in changes) {
        apiRoutePointChanges.address = prepareLocation(changes[RoutePointFieldsEnum.location] || null);
    }

    return apiRoutePointChanges;
};

const prepareApiReserveChangesAddRoutePoint = (
    index: number,
    routePointValues: Partial<RoutePointFormValuesT>,
): ReserveQueryChangesT => {
    return {
        addRoutePoint: {
            index,
            changes: prepareApiReserveRoutePointChanges(routePointValues),
        },
    };
};

const prepareApiReserveChangesDeleteRoutePoint = (index: number): ReserveQueryChangesT => {
    return {
        deleteRoutePoint: {
            index,
        },
    };
};

const prepareApiReserveChangesChangeRoutePoint = (
    index: number,
    routePointValues: Partial<RoutePointFormValuesT>,
): ReserveQueryChangesT => {
    return {
        routePointChange: {
            index,
            changes: prepareApiReserveRoutePointChanges(routePointValues),
        },
    };
};

const prepareApiReserveChanges = (changes: AvailableChangesT): ReserveQueryChangesT => {
    let apiChanges: ReserveQueryChangesT = {};

    if (FieldsEnum.shipperId in changes) {
        apiChanges = {
            ...apiChanges,
            shipperId: changes[FieldsEnum.shipperId],
        };
    }

    if (FieldsEnum.trailerDictId in changes) {
        apiChanges = {
            ...apiChanges,
            trailerDictId: changes[FieldsEnum.trailerDictId],
        };
    }

    if (FieldsEnum.laneId in changes) {
        apiChanges = {
            ...apiChanges,
            laneId: changes[FieldsEnum.laneId],
        };
    }

    if (FieldsEnum.excludedCountries in changes) {
        apiChanges = {
            ...apiChanges,
            excludedCountries: changes[FieldsEnum.excludedCountries],
        };
    }

    if (FieldsEnum.route in changes) {
        apiChanges = {
            ...apiChanges,
            fullRouteChange: changes[FieldsEnum.route]?.map((routePointChanges) => {
                return prepareApiReserveRoutePointChanges(routePointChanges);
            }),
        };
    }

    return apiChanges;
};

const prepareApiRoutePointReserveChanges = (
    routePointIndex: number,
    changes: AvailableRoutePointChangesT,
): Array<Partial<RoutePointFormValuesT>> => {
    const routePointValues: Array<Partial<RoutePointFormValuesT>> = [];

    if (RoutePointFieldsEnum.location in changes) {
        routePointValues[routePointIndex] = {
            ...routePointValues[routePointIndex],
            [RoutePointFieldsEnum.location]: changes[RoutePointFieldsEnum.location],
        };
    }

    if (RoutePointFieldsEnum.isBrokerTimeWindow in changes) {
        routePointValues[routePointIndex] = {
            ...routePointValues[routePointIndex],
            [RoutePointFieldsEnum.isBrokerTimeWindow]: changes[RoutePointFieldsEnum.isBrokerTimeWindow],
        };
    }

    if (RoutePointFieldsEnum.timeWindow in changes) {
        routePointValues[routePointIndex] = {
            ...routePointValues[routePointIndex],
            [RoutePointFieldsEnum.timeWindow]: changes[RoutePointFieldsEnum.timeWindow],
        };
    }

    return routePointValues;
};

export {
    prepareApiActualOrderRequest,
    prepareApiReserveChanges,
    prepareApiReserveChangesAddRoutePoint,
    prepareApiReserveChangesDeleteRoutePoint,
    prepareApiReserveChangesChangeRoutePoint,
    prepareApiRoutePointReserveChanges,
};
