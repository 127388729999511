import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork, ForkEffect } from 'redux-saga/effects';

import authSaga from 'common/store/auth/sagas';
import userSaga from 'common/store/user/sagas';
import companySaga from 'common/store/company/sagas';
import trailersDictSaga from 'common/store/trailers-dict/sagas';
import trailersSaga from 'common/store/trailers/sagas';
import trucksDictSaga from 'common/store/trucks-dict/sagas';
import trucksSaga from 'common/store/trucks/sagas';
import errorsSaga from 'common/store/errors/sagas';
import partnersSaga from './partners/sagas';
import partnersStatsSaga from './partners-stats/sagas';
import managersSaga from './managers/sagas';
import dispatchersSaga from './dispatchers/sagas';
import partnerSaga from './partner/sagas';
import countriesDictSaga from 'common/store/countries-dict/sagas';
import documentsSaga from 'common/store/documents/sagas';
import documentsDictSaga from 'common/store/documents-dict/sagas';
import legalFormsDictSaga from 'common/store/legal-forms-dict/sagas';
import priceSettingsSaga from './price-settings/sagas';
import brokerOrdersSaga from './orders/sagas';
import dispatchAssigmentSaga from './dispatch-assigment/sagas';
import dispatchEditSaga from './dispatch-edit/sagas';
import vehicleSchedulesSaga from 'common/store/vehicle-schedules/sagas';
import carriersUtilizationSaga from 'common/store/carriers-utilization/sagas';
import carrierSuggestSaga from 'common/store/carrier-suggest/sagas';
import shipperSuggestSaga from 'common/store/shipper-suggest/sagas';
import additionalServicesDictSaga from 'common/store/additional-services-dict/sagas';
import orderCreationSaga from 'common/store/order-creation/sagas';
import dispatchesSaga from './dispatches/sagas';
import dispatchDetailsSaga from './dispatch-details/sagas';
import dispatchStatsSaga from './dispatch-stats/sagas';
import membersSaga from 'common/store/members/sagas';
import driversSaga from 'common/store/drivers/sagas';
import notificationsSaga from 'common/store/notifications/sagas';
import notificationsAffectsSaga from './notifications/sagas';
import assetTrackSaga from 'common/store/asset-track/sagas';
import carrierContractsSaga from 'common/store/carrier-contracts/sagas';
import transportOrdersSaga from 'common/store/transport-orders/sagas';
import transportOrderDetailsSaga from 'common/store/transport-order-details/sagas';
import groupedNotificationCountsSaga from 'common/store/grouped-notification-counts/sagas';
import gpsTrackingStatusSaga from 'common/store/gps-tracking-status/sagas';
import alertsSaga from 'common/store/alerts/sagas';
import settingsSaga from 'common/store/settings/sagas';
import driverSearchSaga from 'common/store/driver-search/sagas';
import papersSaga from 'common/store/papers/sagas';
import statisticsSaga from './statistics/sagas';
import spotRequestsSaga from 'broker-admin/store/spot-requests/sagas';
import spotRequestsStatsSaga from 'broker-admin/store/spot-requests-stats/sagas';
import spotRequestDetailsSaga from 'broker-admin/store/spot-request-details/sagas';
import spotSaga from './spot/sagas';
import spotAssetsAssigmentSaga from 'common/store/spot-assets-assigment/sagas';
import driverAssigmentSaga from 'common/store/driver-assigment/sagas';
import { invoicesSaga } from './invoices/sagas';
import { shipperContractsSaga } from 'common/store/shipper-contracts/sagas';
import { createCompanySaga } from 'common/store/create-company/sagas';
import { shipperContractLanesSaga } from 'common/store/shipper-contract-lanes/sagas';
import { shipperContractLaneCreationSaga } from 'common/store/shipper-contract-lane-creation/sagas';
import { shipperContractLaneDetailsSaga } from 'common/store/shipper-contract-lane-details/sagas';
import { countrySettingsDictSaga } from 'common/store/country-settings-dict/sagas';

import authReducer from 'common/store/auth/reducer';
import userReducer from 'common/store/user/reducer';
import companyReducer from 'common/store/company/reducer';
import errorsReducer from 'common/store/errors/reducer';
import trailersDictReducer from 'common/store/trailers-dict/reducer';
import trailersReducer from 'common/store/trailers/reducer';
import trucksDictReducer from 'common/store/trucks-dict/reducer';
import trucksReducer from 'common/store/trucks/reducer';
import partnersReducer from './partners/reducer';
import partnersStatsReducer from './partners-stats/reducer';
import managersReducer from './managers/reducer';
import dispatchersReducer from './dispatchers/reducer';
import partnerReducer from './partner/reducer';
import countriesDictReducer from 'common/store/countries-dict/reducer';
import documentsReducer from 'common/store/documents/reducer';
import documentsDictReducer from 'common/store/documents-dict/reducer';
import legalFormsDictReducer from 'common/store/legal-forms-dict/reducer';
import priceSettingsReducer from './price-settings/reducer';
import brokerOrdersReducer from './orders/reducer';
import dispatchAssigmentReducer from './dispatch-assigment/reducer';
import dispatchEditReducer from './dispatch-edit/reducer';
import dispatchesReducer from './dispatches/reducer';
import vehicleSchedulesReducer from 'common/store/vehicle-schedules/reducer';
import carriersUtilizationReducer from 'common/store/carriers-utilization/reducer';
import carrierSuggestReducer from 'common/store/carrier-suggest/reducer';
import shipperSuggestReducer from 'common/store/shipper-suggest/reducer';
import orderCreationReducer from 'common/store/order-creation/reducer';
import additionalServicesDictReducer from 'common/store/additional-services-dict/reducer';
import dispatchDetailsReducer from './dispatch-details/reducer';
import dispatchStatsReducer from './dispatch-stats/reducer';
import membersReducer from 'common/store/members/reducer';
import driversReducer from 'common/store/drivers/reducer';
import notificationsReducer from 'common/store/notifications/reducer';
import assetTrackReducer from 'common/store/asset-track/reducer';
import carrierContractsReducer from 'common/store/carrier-contracts/reducer';
import carrierContractDetailsReducer from 'common/store/carrier-contract-details/reducer';
import transportOrdersReducer from 'common/store/transport-orders/reducer';
import transportOrderDetailsReducer from 'common/store/transport-order-details/reducer';
import groupedNotificationCountsReducer from 'common/store/grouped-notification-counts/reducer';
import gpsTrackingStatusReducer from 'common/store/gps-tracking-status/reducer';
import alertsReducer from 'common/store/alerts/reducer';
import settingsReducer from 'common/store/settings/reducer';
import driverSearchReducer from 'common/store/driver-search/reducer';
import papersReducer from 'common/store/papers/reducer';
import statisticsReducer from './statistics/reducer';
import spotRequestsReducer from 'broker-admin/store/spot-requests/reducer';
import spotRequestsStatsReducer from 'broker-admin/store/spot-requests-stats/reducer';
import spotRequestDetailsReducer from './spot-request-details/reducer';
import spotReducer from './spot/reducer';
import spotAssetsAssigmentReducer from 'common/store/spot-assets-assigment/reducer';
import driverAssigmentReducer from 'common/store/driver-assigment/reducer';
import invoicesReducer from './invoices/reducer';
import shipperContractsReducer from 'common/store/shipper-contracts/slice';
import shipperContractLanesReducer from 'common/store/shipper-contract-lanes/slice';
import shipperContractDetailsReducer from 'common/store/shipper-contract-details/slice';
import createCompanyReducer from 'common/store/create-company/slice';
import shipperLaneCreationReducer from 'common/store/shipper-contract-lane-creation/slice';
import shipperContractLaneDetailsReducer from 'common/store/shipper-contract-lane-details/slice';
import routingGeometryReducer from 'common/store/routing-geometry/slice';

import { CompanyTypeEnum } from 'common/constants';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import carrierContractDetailsSaga from 'common/store/carrier-contract-details/sagas';
import { shipperContractDetailsSaga } from 'common/store/shipper-contract-details/sagas';
import { routingGeometrySaga } from 'common/store/routing-geometry/sagas';
import stateMachineReducer from 'common/store/state-machine/slice';
import { stateMachineSaga } from 'common/store/state-machine/sagas';
import countrySettingsDictReducer from 'common/store/country-settings-dict/reducer';

const rootReducer = combineReducers({
    auth: authReducer(AuthProfileTypeEnum.broker),
    user: userReducer,
    company: companyReducer,
    errors: errorsReducer,
    trailersDict: trailersDictReducer,
    trailers: trailersReducer,
    trucksDict: trucksDictReducer,
    trucks: trucksReducer,
    partners: partnersReducer,
    partnersStats: partnersStatsReducer,
    managers: managersReducer,
    dispatchers: dispatchersReducer,
    partner: partnerReducer,
    members: membersReducer,
    drivers: driversReducer,
    countriesDict: countriesDictReducer,
    countrySettingsDict: countrySettingsDictReducer,
    documents: documentsReducer,
    documentsDict: documentsDictReducer,
    legalFormsDict: legalFormsDictReducer,
    priceSettings: priceSettingsReducer,
    orders: brokerOrdersReducer,
    dispatchAssigment: dispatchAssigmentReducer,
    dispatchEdit: dispatchEditReducer,
    vehicleSchedules: vehicleSchedulesReducer,
    carriersUtilization: carriersUtilizationReducer,
    carrierSuggest: carrierSuggestReducer,
    shipperSuggest: shipperSuggestReducer,
    orderCreation: orderCreationReducer,
    additionalServicesDict: additionalServicesDictReducer,
    dispatches: dispatchesReducer,
    dispatchDetails: dispatchDetailsReducer,
    dispatchStats: dispatchStatsReducer,
    notifications: notificationsReducer,
    assetTrack: assetTrackReducer,
    carrierContracts: carrierContractsReducer,
    carrierContractDetails: carrierContractDetailsReducer,
    transportOrders: transportOrdersReducer,
    transportOrderDetails: transportOrderDetailsReducer,
    groupedNotificationCounts: groupedNotificationCountsReducer,
    gpsTrackingStatus: gpsTrackingStatusReducer,
    alerts: alertsReducer,
    settings: settingsReducer,
    driverSearch: driverSearchReducer,
    papers: papersReducer,
    statistics: statisticsReducer,
    spotRequests: spotRequestsReducer,
    spotRequestsStats: spotRequestsStatsReducer,
    spotRequestDetails: spotRequestDetailsReducer,
    spot: spotReducer,
    spotAssetsAssigment: spotAssetsAssigmentReducer,
    driverAssignment: driverAssigmentReducer,
    invoices: invoicesReducer,
    createCompany: createCompanyReducer,
    shipperContracts: shipperContractsReducer,
    shipperContractLanes: shipperContractLanesReducer,
    shipperContractDetails: shipperContractDetailsReducer,
    shipperContractLaneCreation: shipperLaneCreationReducer,
    shipperContractLaneDetails: shipperContractLaneDetailsReducer,
    routingGeometry: routingGeometryReducer,
    stateMachine: stateMachineReducer,
});

export type RootStateT = ReturnType<typeof rootReducer>;

function* rootSaga(): Generator<ForkEffect<TODO>, void, TODO> {
    yield fork(authSaga, CompanyTypeEnum.broker);
    yield fork(userSaga);
    yield fork(companySaga);
    yield fork(trailersDictSaga);
    yield fork(trailersSaga);
    yield fork(trucksDictSaga);
    yield fork(trucksSaga);
    yield fork(errorsSaga);
    yield fork(partnersSaga);
    yield fork(partnersStatsSaga);
    yield fork(managersSaga);
    yield fork(dispatchersSaga);
    yield fork(partnerSaga);
    yield fork(countriesDictSaga);
    yield fork(countrySettingsDictSaga);
    yield fork(documentsSaga);
    yield fork(documentsDictSaga);
    yield fork(legalFormsDictSaga);
    yield fork(priceSettingsSaga);
    yield fork(driverSearchSaga);
    yield fork(papersSaga);
    yield fork(statisticsSaga);
    yield fork(spotRequestsSaga);
    yield fork(spotRequestsStatsSaga);
    yield fork(spotRequestDetailsSaga);
    yield fork(brokerOrdersSaga);
    yield fork(dispatchAssigmentSaga);
    yield fork(dispatchEditSaga);
    yield fork(vehicleSchedulesSaga, CompanyTypeEnum.broker);
    yield fork(carriersUtilizationSaga);
    yield fork(carrierSuggestSaga);
    yield fork(shipperSuggestSaga);
    yield fork(dispatchesSaga);
    yield fork(dispatchDetailsSaga);
    yield fork(dispatchStatsSaga);
    yield fork(membersSaga);
    yield fork(driversSaga);
    yield fork(additionalServicesDictSaga);
    yield fork(orderCreationSaga, CompanyTypeEnum.broker);
    yield fork(notificationsSaga);
    yield fork(notificationsAffectsSaga);
    yield fork(transportOrdersSaga);
    yield fork(transportOrderDetailsSaga, CompanyTypeEnum.broker);
    yield fork(assetTrackSaga, CompanyTypeEnum.broker);
    yield fork(carrierContractsSaga);
    yield fork(carrierContractDetailsSaga);
    yield fork(groupedNotificationCountsSaga);
    yield fork(gpsTrackingStatusSaga);
    yield fork(alertsSaga);
    yield fork(settingsSaga);
    yield fork(spotSaga);
    yield fork(spotAssetsAssigmentSaga);
    yield fork(driverAssigmentSaga);
    yield fork(invoicesSaga);
    yield fork(shipperContractsSaga, CompanyTypeEnum.broker);
    yield fork(shipperContractLanesSaga, CompanyTypeEnum.broker);
    yield fork(shipperContractDetailsSaga, CompanyTypeEnum.broker);
    yield fork(createCompanySaga, CompanyTypeEnum.broker);
    yield fork(shipperContractLaneCreationSaga);
    yield fork(shipperContractLaneDetailsSaga, CompanyTypeEnum.broker);
    yield fork(routingGeometrySaga, CompanyTypeEnum.broker);
    yield fork(stateMachineSaga, CompanyTypeEnum.broker);
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

// for dev
window.store = store;

export default store;
