import classNames from 'classnames/bind';
import styles from './MapTooltipMenu.scss';
import * as React from 'react';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import noop from 'lodash/noop';

const cx = classNames.bind(styles);

export type MapTooltipMenuItemT = {
    label: React.ReactNode;
    onSelect: () => void;
    testSelector?: string;
    isDisabled?: boolean;
    tooltipContent?: React.ReactNode;
};

type PropsT = {
    className?: string;
    onOutsideClick: () => void;
    items: Array<MapTooltipMenuItemT>;
};

const MapTooltipMenu: React.FC<PropsT> = React.memo((props) => {
    const { className, onOutsideClick, items } = props;

    return (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
            <div
                className={cx('overlay', className)}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {items.map((item, index) => (
                    <Tooltip
                        key={index}
                        position={TooltipPositionEnum.centerRight}
                        theme={TooltipThemeEnum.black}
                        tooltipNode={
                            item?.tooltipContent ? (
                                <TooltipContent width={150} theme={TooltipContentThemeEnum.black}>
                                    {item?.tooltipContent}
                                </TooltipContent>
                            ) : null
                        }
                    >
                        {() => (
                            <div
                                key={`option-${index}`}
                                className={cx('option', {
                                    'option--isDisabled': item.isDisabled,
                                })}
                                data-testid={`option-${item.testSelector}`}
                                onClick={!item?.isDisabled ? item.onSelect : noop}
                            >
                                {item.label}
                            </div>
                        )}
                    </Tooltip>
                ))}
            </div>
        </OutsideClickHandler>
    );
});

export default MapTooltipMenu;
