import * as React from 'react';

import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './RuleCell.scss';
import { RuleT } from 'broker-admin/store/price-settings/rules/models';
import { TFunction } from 'i18next';
import min from 'lodash/min';
import max from 'lodash/max';
import moment from 'moment';
import RuleImpact from 'common/components/RuleImpact/RuleImpact';
import isNil from 'lodash/isNil';

type PropsT = {
    rule: RuleT;
};

const cx = classNames.bind(styles);

const DAYS_LIMIT = 2;

const formatDays = (t: TFunction, repeatDays: Array<number>): string => {
    return (
        repeatDays
            .map((dayNumber) => t(`common:day-name.${dayNumber - 1}`))
            .slice(0, DAYS_LIMIT)
            .join(', ') + (repeatDays.length > DAYS_LIMIT ? ` +${repeatDays.length - DAYS_LIMIT}` : '')
    );
};

const formatDates = (t: TFunction, repeatDays: Array<number>): string | null => {
    const minValue = min(repeatDays);
    const maxValue = max(repeatDays);

    if (minValue === maxValue) {
        return moment(minValue).format('DD MMM');
    }

    if (isNil(minValue) || isNil(maxValue)) {
        const value = minValue || maxValue;
        return moment(value).format('DD MMM');
    }

    const isSameMonth = new Date(minValue).getMonth() === new Date(maxValue).getMonth();
    if (isSameMonth) {
        return [moment(minValue).format('DD'), moment(maxValue).format('DD MMM')].join('-');
    }

    if (minValue && maxValue) {
        return [moment(minValue).format('DD MMM'), moment(maxValue).format('DD MMM')].join('-');
    }

    return null;
};

const RuleCell: React.FC<PropsT> = React.memo((props) => {
    const { rule } = props;

    const { repeatDays } = rule;

    const { t } = useTranslation();

    const isDays = repeatDays.every((date) => date < 10);

    return (
        <div className={cx('content')}>
            <div>{isDays ? formatDays(t, repeatDays) : formatDates(t, repeatDays)}</div>
            <div>
                <RuleImpact value={rule.value} targetValueType={rule.targetValueType} />
            </div>
        </div>
    );
});

export default RuleCell;
